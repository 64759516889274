import axios from "axios";
import { url, AuthHeader } from "../../utils/_exports";
const apiURL = "wells";

export const getWells = (tenantKey: any, filter: any) => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${url}/${tenantKey}/${apiURL}`, {
                ...AuthHeader,
                params: { filter: filter },
            })
            .then((_data: any) => {
                if (_data?.data && _data.data.length) {
                    resolve(_data.data);
                } else {
                    resolve(null);
                }
            })
            .catch((e: any) => {
                reject(e);
            });
    });
};

export const addWellItem = (tenantKey: any, data: any, user: any) => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${url}/${tenantKey}/${apiURL}`, { ...AuthHeader, params: { filter: { well_name: data.well_name } } })
            .then((result: any) => {
                if (result.data.length > 0) {
                    reject({
                        code: 11000,
                        keyPattern: {
                            well_name: "",
                        },
                    });
                } else {
                    axios.post(`${url}/${tenantKey}/${apiURL}/add`, { data, user }, AuthHeader)
                        .then((_data: any) => {
                            resolve(_data.data);
                        })
                        .catch((e: any) => {
                            reject(e?.response?.data?.error);
                        });
                }
            }).catch((e: any) => {
                reject(e?.response?.data?.error);
            });
    });
}

export const updateWellItem = (
    tenantKey: any,
    id: any,
    data: any,
    initialData: any,
    user: any,
    formList: any = null
) => {
    return new Promise((resolve, reject) => {
        axios
            .put(
                `${url}/${tenantKey}/${apiURL}/update`,
                {
                    filter: { _id: id },
                    data,
                    initialData,
                    user,
                    formList,
                    options: { upsert: true, new: true },
                },
                AuthHeader
            )
            .then((_data: any) => {
                resolve(_data.data);
            })
            .catch((e: any) => {
                reject(e?.response?.data?.error);
            });
    });
};

export const deleteWellItem = (
    tenantKey: any,
    id: any,
    user: any
) => {
    return new Promise((resolve, reject) => {
        axios
            .post(
                `${url}/${tenantKey}/${apiURL}/delete`,
                {
                    filter: {
                        _id: id,
                    },
                    user: user,
                },
                { ...AuthHeader }
            )
            .then((_data) => {
                resolve(true);
            })
            .catch((e: any) => {
                reject(e?.response?.data?.error);
            });
    });
};

export const unlinkWellFromProject = (tenantKey: any, wellId: any, projectId: any, user: any) => {
    return new Promise((resolve, reject) => {
        axios
            .post(
                `${url}/${tenantKey}/${apiURL}/unlink-well-from-project`,
                {
                    filter: {
                        _id: wellId,
                    },
                    projectId: projectId,
                    user: user,
                },
                { ...AuthHeader }
            )
            .then((_data: any) => {
                resolve(_data.data);
            })
            .catch((e: any) => {
                reject(e?.response?.data?.error);
            });
    });
}

export const linkWellToProject = (tenantKey: any, wellId: any, projectId: any, data: any, user: any) => {
    return new Promise((resolve, reject) => {
        axios
            .post(
                `${url}/${tenantKey}/${apiURL}/link-well-to-project`,
                {
                    filter: {
                        _id: wellId,
                    },
                    data,
                    projectId: projectId,
                    user: user,
                },
                { ...AuthHeader }
            )
            .then((_data: any) => {
                resolve(_data.data);
            })
            .catch((e: any) => {
                reject(e?.response?.data?.error);
            });
    });
}