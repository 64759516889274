import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Input, Modal, Popconfirm, Tag, Tooltip } from "antd";

function TemplateDisabledModal(props: any) {
	const setOpen = props?.setOpen;
	const title = props?.title;
	const message = props?.message;
	const level = props?.level;
	const id = props?.id;
	const open = props.open;
	const handleTemplateModalSave = props?.handleTemplateModalSave;

	const [comment, setComment] = useState<any>("");

	return (
		<Modal
			closable={false}
			maskClosable={false}
			width={488}
			title={title}
			open={open}
			centered
			okText={"Save"}
			styles={{ body: { padding: "12px 30px" } }}
			okButtonProps={{ disabled: comment === "" }}
			onOk={() => {
				setComment("");
				handleTemplateModalSave(id, level, {
					disabled: true,
					disabled_reason: comment,
				});
			}}
			onCancel={() => {
				setComment("");
				setOpen(false);
			}}
			destroyOnClose
		>
			<div>
				<span>{message}</span>
				<Input.TextArea
					style={{ resize: "none" }}
					draggable={false}
					value={comment}
					onChange={(ev: any) => {
						let value = ev.target.value.replace(/^ /, "").replace(/\s+/g, " ");

						setComment(value);
					}}
				/>
			</div>
		</Modal>
	);
}

export default TemplateDisabledModal;
