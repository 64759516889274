import axios from "axios";
import { url, AuthHeader, superAdminRole } from "../../utils/_exports";
const apiURL = "well-programme-template";

export const getWellProgrammeTemplateList = (tenantKey: any) => {
	return new Promise((resolve, reject) => {
		axios
			.get(`${url}/${tenantKey}/${apiURL}`, {
				...AuthHeader,
			})
			.then((_data: any) => {
				if (_data?.data && _data.data.length) {
					resolve(_data.data);
				} else {
					resolve(null);
				}
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const addWellProgrammeTemplate = (
	tenantKey: any,
	data: any,
	user: any
) => {
	return new Promise((resolve, reject) => {
		axios
			.post(`${url}/${tenantKey}/${apiURL}/add`, { data, user }, AuthHeader)
			.then((_data: any) => {
				resolve(_data.data);
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const updateWellProgrammeTemplate = (
	tenantKey: any,
	id: any,
	data: any,
	initialData: any,
	user: any,
	formList: any
) => {
	return new Promise((resolve, reject) => {
		axios
			.put(
				`${url}/${tenantKey}/${apiURL}/update`,
				{
					filter: { _id: id },
					data,
					initialData,
					user,
					formList,
					options: { upsert: true, new: true },
				},
				AuthHeader
			)
			.then((_data: any) => {
				resolve(_data.data);
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

type LevelType = "phase" | "task" | "deliverable";
type ProcessType = "add" | "update" | "delete" | "reorder";
export const findAndUpdateWellProgrammeTemplate = (
	tenantKey: any,
	id: any,
	data: any,
	user: any,
	level: LevelType,
	processType: ProcessType,
	ref_id?: any,
	initialData?: any,
	formList?: any
) => {
	return new Promise((resolve, reject) => {
		axios
			.put(
				`${url}/${tenantKey}/${apiURL}/findUpdate`,
				{
					filter: { _id: id },
					data: data,
					initialData: initialData,
					options: { upsert: true, new: true },
					level: level,
					processType: processType,
					user: user,
					formList: formList,
					ref_id: ref_id,
				},
				AuthHeader
			)
			.then((_data: any) => {
				resolve(_data.data);
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const deleteWellProgrammeTemplate = (
	tenantKey: any,
	id: any,
	user: any
) => {
	return new Promise((resolve, reject) => {
		axios
			.post(
				`${url}/${tenantKey}/${apiURL}/delete`,
				{
					filter: {
						_id: id,
					},
					user: user,
				},
				{ ...AuthHeader }
			)
			.then((_data) => {
				resolve(true);
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};
