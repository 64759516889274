import { defaultTheme } from "antd/es/theme/context";
import { createContext, useContext } from "react";

export type MainContextProps = {
	menuOption?: string;
	setMenuOption?: (option: string) => void;
	kognifaiTags?: {};
	setKognifaiTags?: (tags: any) => void;
	location?: {};
};

export const MainContext = createContext<MainContextProps>({
	menuOption: "default",
	setMenuOption: () => { },
	kognifaiTags: {},
	setKognifaiTags: () => { },
	location: {},
});

interface Theme {
	mode: "light" | "dark";
	defaultTheme: boolean,
	setDefaultTheme: any;
	themeVariables: any;
	setThemeVariables: any;
}

export const ThemeContext = createContext<Theme | undefined>(undefined);

export function useThemeContext() {
	const theme = useContext(ThemeContext);
	if (!theme) {
		throw new Error("useThemeContext must be used within a ThemeProvider");
	}

	return theme;
}