import axios from "axios";
import { url, AuthHeader } from "../../utils/_exports";
const apiURL = "operations";

export const getOperations = (tenantKey: any, filter: any) => {
	return new Promise((resolve, reject) => {
		axios
			.get(`${url}/${tenantKey}/${apiURL}`, {
				...AuthHeader,
				params: { filter: filter },
			})
			.then((_data: any) => {
				if (_data?.data && _data.data.length) {
					resolve(_data.data);
				} else {
					resolve(null);
				}
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

// Combine operations data with its well data
// export const getWellOperation = (tenantKey: any, operation_code: any) => {
//     return new Promise((resolve, reject) => {
//         axios
//             .get(`${url}/${tenantKey}/${apiURL}/wellOperation`, {
//                 ...AuthHeader,
//                 params: { operation_code: operation_code },
//             })
//             .then((_data: any) => {
//                 if (_data?.data && _data.data.length) {
//                     resolve(_data.data);
//                 } else {
//                     resolve(null);
//                 }
//             })
//             .catch((e: any) => {
//                 reject(e);
//             });

//     });
// };

export const addOperationItem = (tenantKey: any, data: any, user: any) => {
	return new Promise((resolve, reject) => {
		axios
			.get(`${url}/${tenantKey}/${apiURL}`, {
				...AuthHeader,
				params: {
					filter: {
						project_code: data.project_code,
						operation_name: data.operation_name,
					},
				},
			})
			.then((result: any) => {
				if (result.data.length > 0) {
					reject({
						code: 11000,
						keyPattern: {
							operation_name: "",
						},
					});
				} else {
					axios
						.post(
							`${url}/${tenantKey}/${apiURL}/add`,
							{ data, user },
							AuthHeader
						)
						.then((_data: any) => {
							resolve(_data.data);
						})
						.catch((e: any) => {
							reject(e?.response?.data?.error);
						});
				}
			})
			.catch((e: any) => {
				reject(e?.response?.data?.error);
			});
	});
};

export const updateOperationItem = (
	tenantKey: any,
	id: any,
	data: any,
	initialData: any,
	user: any,
	formList: any = null
) => {
	return new Promise((resolve, reject) => {
		axios
			.get(`${url}/${tenantKey}/${apiURL}`, {
				...AuthHeader,
				params: {
					filter: {
						_id: { $ne: id },
						project_code: data.project_code,
						operation_name: data.operation_name,
					},
				},
			})
			.then((result: any) => {
				if (result.data.length > 0) {
					reject({
						code: 11000,
						keyPattern: {
							operation_name: "",
						},
					});
				} else {
					axios
						.put(
							`${url}/${tenantKey}/${apiURL}/update`,
							{
								filter: { _id: id },
								data: data,
								initialData,
								user,
								formList,
								options: { upsert: true, new: true },
							},
							AuthHeader
						)
						.then((_data: any) => {
							resolve(_data.data);
						})
						.catch((e: any) => {
							reject(e?.response?.data?.error);
						});
				}
			})
			.catch((e: any) => {
				reject(e?.response?.data?.error);
			});
	});
};

export const deleteOperationItem = (
	tenantKey: any,
	id: any,
	data: any,
	user: any
) => {
	return new Promise((resolve, reject) => {
		axios
			.post(
				`${url}/${tenantKey}/${apiURL}/delete`,
				{
					filter: { _id: id },
					data,
					user,
				},
				AuthHeader
			)
			.then((_data) => {
				resolve(true);
			})
			.catch((e: any) => {
				reject(e?.response?.data?.error);
			});
	});
};
