import React, { useState } from "react";
import {
	MapContainer,
	LayersControl,
	TileLayer,
	Marker,
	Popup,
	useMap,
	Tooltip,
} from "react-leaflet";
import * as L from "leaflet";
import PropTypes from "prop-types";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import Emitter from "../../utils/emitter";
import { Button, Divider, Drawer, Image, Modal, Space } from "antd";
import MarkerClusterGroup from "react-leaflet-cluster";
import { MainContext } from "../../contexts/context";
import "leaflet/dist/leaflet.css";
import complete_map_pin from "../../assets/image/Pins - map (completed).png";
import new_map_pin from "../../assets/image/Pins - map (new).png";
import in_progress_map_pin from "../../assets/image/Pins - map (in progress).png";

const { BaseLayer } = LayersControl;
//const link = "https://github.com/zcreativelabs/react-simple-maps/raw/master/topojson-maps/world-10m.json"
L.tileLayer("https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png", {
	attribution:
		'&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
	subdomains: "abcd",
	maxZoom: 20,
});
const LeafIcon: any = L.Icon.extend({
	options: {},
});

const greenIcon = (isSelected: any) => {
	return new LeafIcon({
		iconUrl: complete_map_pin,
		iconSize: isSelected ? [50, 50] : [30, 30],
		iconAnchor: isSelected ? [20, 50] : [10, 30],
	});
};

const whiteIcon = (isSelected: any) => {
	return new LeafIcon({
		iconUrl: new_map_pin,
		iconSize: isSelected ? [50, 50] : [30, 30],
		iconAnchor: isSelected ? [20, 50] : [10, 30],
	});
};

const blueIcon = (isSelected: any) => {
	return new LeafIcon({
		iconUrl: in_progress_map_pin,
		iconSize: isSelected ? [50, 50] : [30, 30],
		iconAnchor: isSelected ? [20, 50] : [10, 30],
	});
};

const zoomCoords: any = {
	0: 0,
	2: 70,
	3: 35,
	4: 25,
	5: 10,
	6: 5,
	7: 3,
	8: 1,
};

const customClusterIcon = (cluster: any) => {
	return L.divIcon({
		html: `<div><div class='customClusterIconBackground'><span>${cluster.getChildCount()}</span></div></div>`,
		className: "customClusterIcon",
		iconSize: [20, 30],
		iconAnchor: [10, 30],
	});
};

const Map = (props: any) => {
	let keys = "AIzaSyACV-NLNpo-vnrfo-SjBYLSXUEqfGoZhFo";
	let centerLatLng: any = undefined;
	let projectList: any = props.projectList || [];
	const selectProject = props.selectProject;

	const [isCleanMap, setCleanMap] = useState<boolean>(true);
	const [isClean, setClean] = useState<boolean>(true);
	const [map, setMap] = useState<any>(null);

	const centerView = () => {
		if (centerLatLng === undefined) {
			return [30, 0];
		} else {
			return [centerLatLng.lat, centerLatLng.lng];
		}
	};

	const ChangeView = ({ center, zoom }: any) => {
		let _myMap: any;

		const myMap = useMap();
		_myMap = myMap;

		if (isCleanMap) {
			setMap(_myMap);
		}
		if (zoom === undefined) zoom = myMap.getZoom();

		if (isClean != false && props.selectedProjectInfo) {
			myMap.flyTo(
				[
					props.selectedProjectInfo?.latlong[0],
					props.selectedProjectInfo?.latlong[1],
				],
				zoom,
				{
					animate: false,
				}
			);
		} else {
			myMap.flyTo(center, 3, { animate: false });
			if (!isClean) setClean(true);
		}

		return null;
	};

	const addMarkers = (_key: string) => {
		return projectList.map((element: any) => {
			const openPopup = (marker: any) => {
				if (marker !== undefined && marker !== null) marker.openPopup();
			};

			const iconPicker = (status: any, projectCode: any) => {
				const isSelected = projectCode === props.selectedProject;
				switch (status) {
					case "Resolved":
						return greenIcon(isSelected);

					case "In progress":
						return blueIcon(isSelected);

					case "New":
						return whiteIcon(isSelected);

					default:
						return whiteIcon(isSelected);
				}
			};
			return (
				<div key={"marker" + element[props.keyType]}>
					<Marker
						key={element[props.keyType]}
						position={[element.latlong[0], element.latlong[1]]}
						icon={iconPicker(element.status, element[props.keyType])}
						ref={(ref: any) => {}}
						eventHandlers={{
							click: (event: any) => {
								selectProject(element[props.keyType], element);
							},
						}}
					></Marker>
				</div>
			);
		});
	};

	const CheckPopup = () => {
		const myMap = useMap();
		// if (!this.props.selectedVessel) {
		// 	_myMap = myMap;
		// 	_myMap.closePopup();
		// }
		return <></>;
	};

	const mapKey = "map-of-the-world";
	return (
		<MapContainer
			center={[30, 0]}
			minZoom={2}
			zoom={3}
			maxZoom={8}
			scrollWheelZoom={true}
			className={`${
				props.menuOption === "mega" ? "leaflet-container-mega" : ""
			}`}
		>
			<MarkerClusterGroup
				iconCreateFunction={customClusterIcon}
				// maxClusterRadius={100}
				disableClusteringAtZoom={6}
				// spiderfyOnMaxZoom={true}
			>
				<ChangeView center={centerView()} zoom={6} />
				{/* <LayersControl position="topright">
						<BaseLayer name="Google Maps - Hybrid">
							<ReactLeafletGoogleLayer
								noWrap={false}
								apiKey={keys}
								type={"hybrid"}
							/>
						</BaseLayer> */}
				{/* <BaseLayer checked name="OpenStreetMap"> */}
				{/* <ReactLeafletGoogleLayer
					noWrap={false}
					apiKey={"AIzaSyACV-NLNpo-vnrfo-SjBYLSXUEqfGoZhFo"}
					type={"hybrid"}
					// googleMapsLoaderConf={{ apiKey: "", language: "en" }}
				/> */}
				{/* <TileLayer
					noWrap={false}
					// attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
					url="http://{s}.tiles.mapbox.com/v3/{user}.{map}/{z}/{x}/{y}.png"
				/> */}
				<TileLayer
					noWrap={false}
					// attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
					url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
				/>
				{/* </BaseLayer> */}
				{/* </LayersControl> */}
				{addMarkers(mapKey)}
			</MarkerClusterGroup>
		</MapContainer>
	);
};

Map.propTypes = {
	projectList: PropTypes.any,
	selectProject: PropTypes.func,
	selectedProject: PropTypes.any,
	selectedProjectInfo: PropTypes.any,
	keyType: PropTypes.any,
};

export default Map;
