import React, { useContext } from "react";
import { HomeContext } from "../containers/Home";

function useHandleContextMenu() {
	const context: any = useContext(HomeContext);

	const handleContextMenu = (
		event: React.MouseEvent<HTMLButtonElement>,
		contextMenuInfo: { link: string }
	) => {
		event.preventDefault(); // Prevent the default context menu from appearing
		context.setContextMenuPosition({
			mouseX: event.clientX - 2,
			mouseY: event.clientY - 4,
		});
		context.setContextMenuInfo(contextMenuInfo);
	};

	return { handleContextMenu };
}

export default useHandleContextMenu;
