import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { HomeContext } from "./Home";
import { useLocation, useNavigate } from "react-router";
import { Button, Divider, Input, Space, Table, Tabs, Typography } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { GetAntIcon } from "../utils/ant_icons";
import CheckboxDropdown from "../components/CheckboxDropdown";
import { checkPrivilege } from "../utils/utils";
import Map from "../components/Overview/Map";
import { setLookupData } from "../utils/lookupFinder";
import Emitter from "../utils/emitter";
import NoAccess from "./NoAccess";
import SearchButton from "../components/SearchButton";
import AuditLogModal from "../components/Modal/AuditLogModal";
import useHandleContextMenu from "../hooks/useHandleContextMenu";
import useContainerDimensions from "../hooks/useContainerDimensions";
import { getOperations } from "../services/api-server/operations";
import { getWells } from "../services/api-server/wells";

const { Text } = Typography;

// Each object represents a specific property or field for a project, with three attributes
const projectTemplateExample: any = [
	{ label: "Project title", key: "project_name", value: "name" },
	{ label: "Project type", key: "project_type", value: "type" },
	{ label: "Project area", key: "project_area", value: "area" },
	{ label: "Project code", key: "project_code", value: "ABC123456A" },
	{ label: "Owner/Operator", key: "client", value: "Addax Petroleum" },
	{ label: "Region", key: "region", value: "North Europe" },
	{ label: "Country", key: "country", value: "England" },
	{ label: "Operating office", key: "operating_office", value: "Aberdeen" },
];

function ProjectCampaignOverview(props: any) {
	const context: any = useContext(HomeContext);
	const location = useLocation();
	const navigate = useNavigate();
	const [projectInfo, setProjectInfo] = useState<any>(null);
	const [projectTitle, setProjectTitle] = useState<any>(null);
	const [projectDetailsExpand, setProjectDetailsExpand] = useState<any>(false);
	const [wellList, setWellList] = useState<any>(null);
	const [selectedWell, setSelectedWell] = useState<any>(null);
	const [selectedWellInfo, setSelectedWellInfo] = useState<any>(null);
	const [isLoading, setIsLoading] = useState<any>(true);

	//Filtering states
	const [filteredWellList, setFilteredWellList] = useState<any>(null);
	const [filterMode, setFilterMode] = useState<boolean>(false);
	const [searchValue, setSearchValue] = useState<any>("");
	const [selectedFilters, setSelectedFilters] = useState<any>({
		location: [],
		well_purpose: [],
		well_status: [],
	});

	//Table states
	const [dataTable, setDataTable] = useState<any>(null);
	const [filteredDataTable, setFilteredDataTable] = useState<any>(null);
	const [tempRecord, setTempRecord] = useState<any>(null);
	const [searchRecord, setSearchRecord] = useState<any>([]);
	// const [tableHeight, setTableHeight] = useState<any>(null);

	//Audit Logs states
	const [auditOpen, setAuditOpen] = useState<any>(false);

	const { handleContextMenu } = useHandleContextMenu();

	let [panelRef, panelDimensions] = useContainerDimensions();
	let [teamRoleRef, teamRoleDimensions] = useContainerDimensions();

	const handleSearch = (record: any) => {
		setTempRecord(record);
	};

	useEffect(() => {
		if (searchRecord && tempRecord) {
			let recordIndex = searchRecord.findIndex((item: any) => {
				return item.columnKey === tempRecord.columnKey;
			});
			if (recordIndex !== -1) {
				if (tempRecord.value != "") {
					let _record = [...searchRecord];
					_record[recordIndex] = tempRecord;
					setSearchRecord(_record);
				} else {
					let _record = [...searchRecord];
					_record.splice(recordIndex, 1);
					setSearchRecord(_record);
				}
			} else {
				if (tempRecord.value != "") {
					let _record = [...searchRecord];
					_record.push(tempRecord);
					setSearchRecord(_record);
				}
			}
		}
	}, [tempRecord]);

	useEffect(() => {
		if (searchRecord?.length > 0) {
			let data: any = [];

			data = [...dataTable];
			let filteredData = data
				?.map((element: any) => {
					let bool = searchRecord.every((item: any) => {
						if (Array.isArray(element[item.columnKey])) {
							return element[item.columnKey].some((arrayItem: any) =>
								arrayItem.toLowerCase().includes(item.value.toLowerCase())
							);
						} else {
							return element[item.columnKey]
								?.toLowerCase()
								.includes(item.value.toLowerCase());
						}
					});
					if (bool) return element;
				})
				.filter((element: any) => element !== null && element !== undefined);
			setFilteredDataTable(filteredData);
		} else {
			setFilteredDataTable(null);
		}
	}, [searchRecord]);

	useEffect(() => {
		if ((context.currentTenantKey, context.allData)) {
			// calculateTableHeight();
			// window.addEventListener("resize", () => {
			// 	calculateTableHeight();
			// });

			Emitter.emit("loading", true);

			if (localStorage.getItem("selectedFilters")) {
				let selectedFiltersStore: any = localStorage.getItem("selectedFilters");
				let selectedFiltersStoreJson = JSON.parse(selectedFiltersStore);
				setSelectedFilters(selectedFiltersStoreJson);
				setFilterMode(true);
			}

			let project_code: any =
				location?.state?.project_code || location?.pathname?.split("/")[2];

			let foundProject = context.allData?.projects?.find(
				(project: any) => project?.project_code === project_code
			);

			// Have to handle archive
			if (foundProject) {
				setProjectTitle(foundProject.project_name);
				setProjectInfo(foundProject);
				context.setCurrentProject(foundProject);
				const newDataTable = foundProject.role_assignments;
				const projectManagerExists = foundProject.role_assignments.find(
					(role: any) => {
						return role.role_id == "project_manager";
					}
				);
				if (
					foundProject.project_manager &&
					foundProject.project_manager.length > 0 &&
					!projectManagerExists
				) {
					newDataTable.push({
						role_id: "project_manager",
						role: "Project Manager",
						appointee: [foundProject.project_manager],
					});
				}
				setDataTable(foundProject.role_assignments);
			} else {
				setProjectInfo(null);
			}

			// get related wells data and operations data and combine them
			getWells(context.currentTenantKey, { projects: project_code })
				.then(async (wellData: any) => {
					const operationsData: any = [];

					for (const well of wellData) {
						try {
							let operations: any = await getOperations(
								context.currentTenantKey,
								{
									project_code,
									well_id: well._id,
								}
							);
							if (operations?.length > 0) {
								operations.map((operation: any) => {
									operationsData.push({
										...operation,
										...well,
									});
								});
							}
						} catch (err) {}
					}

					// await wellData.map((well: any) => {
					// 	getOperations(context.currentTenantKey, {
					// 		project_code,
					// 		well_id: well._id,
					// 	}).then((operations: any) => {
					// 		if (operations?.length > 0) {
					// 			operations.map((operation: any) => {
					// 				operationsData.push({
					// 					...operation,
					// 					...well,
					// 				});
					// 			});
					// 		}
					// 	});
					// });

					setWellList(operationsData);
					// ).then(() => {
					// 	setWellList(operationsData);
					// });
				})

				.catch((e: any) => {})
				.finally(() => {
					Emitter.emit("loading", false);
					setIsLoading(false);
				});
		}
	}, [context.currentTenantKey, context.allData]);

	const hasSelectedFilters = () => {
		// Check if any of the filter categories have selected values
		return Object.values(selectedFilters).some(
			(values: any) => values.length > 0
		);
	};
	const handleMenuClick = (field: any, selectedValues: any[]) => {
		// Update the selected filters state
		setSelectedFilters({ ...selectedFilters, [field]: selectedValues });

		localStorage.setItem(
			"selectedFilters",
			JSON.stringify({ ...selectedFilters, [field]: selectedValues })
		);
	};

	const handleClearAllFilters = () => {
		// Reset all filters to empty arrays
		setSelectedFilters({
			location: [],
			well_purpose: [],
			well_status: [],
		});
		setFilterMode(false);

		// Clear session storage for filter values and checkboxes
		localStorage.removeItem("selectedFilters");

		// Object.keys(selectedFilters).forEach((field) => {
		// 	const storageKey = generateStorageKey(field);
		// 	localStorage.removeItem(storageKey);
		// });

		setTimeout(() => {
			setFilterMode(true);
		}, 5);
	};

	const selectWell = (wellKey: any, well: any) => {
		if (selectedWell != wellKey) {
			setSelectedWell(wellKey);
			setSelectedWellInfo(well);
			const selectedElement = document.getElementById(`project-${wellKey}`);

			setTimeout(() => {
				if (selectedElement) {
					selectedElement.scrollIntoView({ behavior: "smooth" });
				}
			}, 1);
		} else {
			setSelectedWell(null);
			setSelectedWellInfo(null);
		}
	};

	const renderDetailData = () => {
		// Calculates the number of rows needed by dividing the number of fields/properties in `projectTemplateExample` by 3 (since there are 3 columns)
		const numRows = Math.ceil(Object.keys(projectTemplateExample).length / 3);
		// Calculates the total number of columns based on `numRows` multiplied by 3
		const totalColumns = numRows * 3;
		const leftOverColumns = totalColumns - projectTemplateExample.length;

		return (
			<div
				className="meta-data-container"
				style={{ gridTemplateColumns: "repeat(3, auto)" }}
			>
				{/* 
					Uses map to loop through each item (element) in projectTemplateExample. Each element represents a field/property with details to display.
				*/}
				{projectTemplateExample.map((element: any) => (
					<div
						style={{
							display: "grid",
							gridTemplateRows: "20px auto",
							// If the element.label is "Country", the gridRow spans across two rows
							gridRow: element.label === "Country" ? "span 2" : undefined,
						}}
					>
						{/* Field Label */}
						<span className="panel-detail-label">{element.label}</span>
						{/* Field Content */}
						<span className="panel-item-content">
							{/* Calls `setLookupData` to retrieve or format the value for `element.key` using data in `context.allData` and `projectInfo` */}
							{setLookupData(context.allData, projectInfo, element.key)}
						</span>
					</div>
				))}
				{/* Project Tags */}
				{renderProjectTags()}
			</div>
		);
	};

	const renderProjectTags = () => {
		return (
			<div
				style={{
					display: "grid",
					rowGap: "6px",
					gridTemplateRows: "auto auto",
					gridColumn: "span 3",
				}}
			>
				<span
					className="panel-detail-label"
					style={{
						// color: "rgba(255, 255, 255, 0.45)",
						display: "flex",
						justifyContent: "flex-start",
						fontSize: "12px",
					}}
				>
					Project tags
				</span>
				<span
					style={{
						display: "flex",
						flexWrap: "wrap",
						fontSize: "14px",
						rowGap: "10px",
					}}
				>
					{setLookupData(context.allData, projectInfo, "project_tags")}
				</span>
			</div>
		);
	};

	const listWells = () => {
		const renderPurpose = (
			operation: any,
			purpose: string,
			purposeType: string
		) => {
			switch (purpose) {
				case "In progress":
					return (
						<div className="project-status-item status-counter-in-progress">
							<span>
								{purpose} ({purposeType})
							</span>
						</div>
					);

				case "New":
					return (
						<div className="project-status-item status-counter-new">
							<span>New ({purposeType})</span>
						</div>
					);

				case "Resolved":
					return (
						<div className="project-status-item status-counter-completed">
							<span>Resolved ({purposeType})</span>
						</div>
					);
				default:
					if (purpose) {
						return (
							<div className="project-status-item status-counter-in-progress">
								<span>
									{setLookupData(context.allData, operation, purpose)}{" "}
									{operation?.[purposeType] && (
										<>
											({setLookupData(context.allData, operation, purposeType)})
										</>
									)}
								</span>
							</div>
						);
					} else {
						return <></>;
					}
			}
		};
		const renderStatus = (
			operation: any,
			status: string,
			statusType: string
		) => {
			switch (status) {
				case "In progress":
					return (
						<div className="project-status-item status-counter-in-progress">
							<span>In Progress</span>
						</div>
					);

				case "New":
					return (
						<div className="project-status-item status-counter-new">
							<span>New</span>
						</div>
					);

				case "Resolved":
					return (
						<div className="project-status-item status-counter-completed">
							<span>Resolved</span>
						</div>
					);
				default:
					if (status) {
						return (
							<div className="project-status-item status-counter-in-progress">
								<span>
									{setLookupData(context.allData, operation, status)}{" "}
									{operation?.[statusType] && (
										<>
											({setLookupData(context.allData, operation, statusType)})
										</>
									)}
								</span>
							</div>
						);
					} else {
						return <></>;
					}
			}
		};

		const renderDetail = (operation: any) => {
			const showDetailInfo = () => {
				const infoKeyList: any = [
					{ label: "License", key: "license" },
					{ label: "Operation type", key: "operation_type" },
					{ label: "Location", key: "location" },
					{ label: "Est. duration (days)", key: "estimated_duration" },
				];

				return (
					<>
						{infoKeyList.map((infoKey: any) => {
							return (
								<Space direction="vertical" size={0}>
									<span
										style={{
											// color: "rgba(255,255,255,0.45)",
											opacity: 0.45,
											fontSize: "12px",
										}}
									>
										{infoKey.label}
									</span>
									<div
										style={{
											display: "flex",
											justifyContent: "flex-start",
											alignItems: "center",
											fontSize: "14px",
											minWidth: "100%",
											width: "0",
										}}
									>
										<Text
											title=""
											style={
												infoKey.key == "country"
													? {
															whiteSpace: "nowrap",
															overflow: "hidden",
															textOverflow: "ellipsis",
													  }
													: {}
											}
											ellipsis={{
												// onEllipsis: () => {},
												tooltip: (
													<span>{setWellContent(operation, infoKey.key)}</span>
												),
											}}
										>
											<span
												style={
													infoKey.key == "country"
														? {
																whiteSpace: "nowrap",
																overflow: "hidden",
																textOverflow: "ellipsis",
														  }
														: {}
												}
											>
												{setWellContent(operation, infoKey.key)}
											</span>
										</Text>
									</div>
									{/* <span>{setProjectContent(project, infoKey.key)}</span> */}
								</Space>
							);
						})}
					</>
				);
			};

			return (
				<div className="project-detailed-container">
					<Space direction="vertical" style={{ width: "100%" }} size={15}>
						<Space
							direction="vertical"
							size={0}
							style={{ display: "flex", flexDirection: "column" }}
						>
							<span
								style={{
									// color: "rgba(255,255,255,0.45)",
									opacity: 0.45,
									fontSize: "14px",
									lineHeight: "25px",
								}}
							>
								Operation title
							</span>
							<div style={{ fontSize: "20px", lineHeight: "25px" }}>
								<span
									className={
										// checkPrivilege(props.params.userRole, ["project-manager"])
										// 	? "project-title-clickable"
										// 	: ""
										"project-title-clickable"
									}
									style={{
										whiteSpace: "normal",
										display: "inline",
										overflowWrap: "break-word",
									}}
									onClick={() => {
										// if (
										// 	checkPrivilege(props.params.userRole, ["project-manager"])
										// ) {
										// Emitter.emit("projectName", wellProject.project_name);
										// localStorage.setItem(
										// 	"currentProject",
										// 	project.project_code
										// );
										navigate(`${operation.operation_code}`, {
											replace: false,
											state: {
												...operation,
												state: { ...operation, projectTitle, projectInfo },
											},
										});
										// }
									}}
									onContextMenu={(event: any) => {
										const location = `${window.location.href}/${operation.operation_code}`;
										handleContextMenu(event, { link: location });
									}}
								>
									{operation.operation_name}
								</span>
							</div>
						</Space>
						<div className="project-status">
							{renderPurpose(operation, "well_purpose", "well_purpose_type")}
							{renderStatus(operation, "well_status", "well_status_type")}
						</div>
						<div className="project-grid-container">{showDetailInfo()}</div>
					</Space>
				</div>
			);
		};

		const renderCompact = (operation: any) => {
			return (
				<div className="project-mini-container">
					<Space direction="vertical" size={9}>
						<Space
							direction="vertical"
							size={0}
							style={{ display: "flex", flexDirection: "column" }}
						>
							<span
								style={{
									opacity: 0.45,
									fontSize: "12px",
								}}
							>
								Operation title
							</span>
							<span
								className={"project-title-clickable"}
								style={{
									whiteSpace: "normal",
									display: "inline",
									overflowWrap: "break-word",
								}}
								onClick={() => {
									navigate(operation.operation_code, {
										replace: false,
										state: { ...operation, projectTitle, projectInfo },
									});
								}}
								onContextMenu={(event: any) => {
									const location = `${window.location.href}/${operation.operation_code}`;
									handleContextMenu(event, { link: location });
								}}
							>
								{operation?.operation_name}
							</span>
						</Space>
						<div className="project-status">
							{renderPurpose(operation, "well_purpose", "well_purpose_type")}
							{"  "}
							{renderStatus(operation, "well_status", "well_status_type")}
						</div>
					</Space>
				</div>
			);
		};
		let currentWellList = filteredWellList || wellList;
		if (currentWellList.length > 0) {
			return currentWellList?.map((element: any) => {
				return (
					<div
						id={`project-${element.operation_code}`}
						className={`overview-card-list-container`}
						onClick={() => {
							selectWell(element.operation_code, element);
						}}
					>
						{element.operation_code === selectedWell ? (
							<>{renderDetail(element)}</>
						) : (
							<>{renderCompact(element)}</>
						)}
					</div>
				);
			});
		} else {
			return <span>No data found.</span>;
		}
	};

	const setWellContent = (currentWellData: any, infoKey: any) => {
		let content = "-";
		if (currentWellData[infoKey]) {
			content = setLookupData(context.allData, currentWellData, infoKey);
			if (infoKey === "location") {
				content =
					currentWellData[infoKey].charAt(0).toUpperCase() +
						currentWellData[infoKey].slice(1) || "-";
			}
		}
		return content;
	};
	useEffect(() => {
		let filteredWellList = null;
		if (searchValue != "") {
			let filteredWell = wellList?.filter((well: any) =>
				well.operation_name?.toLowerCase().includes(searchValue.toLowerCase())
			);
			filteredWellList = filteredWell;
		} else {
			filteredWellList = wellList;
		}
		const check = Object.keys(selectedFilters).some(
			(filterKey: any) => selectedFilters?.[filterKey]?.length > 0
		);
		if (check && filteredWellList) {
			filteredWellList = filteredWellList.filter((well: any) =>
				Object.keys(selectedFilters).every((filterKey: any) => {
					if (selectedFilters[filterKey].length == 0) {
						return true;
					}
					return selectedFilters[filterKey].includes(well[filterKey]);
				})
			);
		}

		setFilteredWellList(filteredWellList);
	}, [searchValue, selectedFilters]);

	return (
		<>
			{projectInfo && !isLoading && (
				<div
					className="generic-container"
					style={{ gap: "20px", overflow: "auto" }}
				>
					{/* Top Container */}
					<div className="main-container" style={{ width: "100%" }}>
						<div className="container-header">
							<div
								className="panel-info"
								onClick={() => {
									setProjectDetailsExpand(!projectDetailsExpand);
								}}
							>
								{/* Project details */}
								<span>Project details</span>
								{/* Project details dropdown icon */}
								{projectDetailsExpand
									? GetAntIcon("up")
									: GetAntIcon("downarrow")}
							</div>
						</div>

						{/* Expanded Project Details */}
						<div
							className="details-content project-details-container"
							style={{
								display: projectDetailsExpand ? "flex" : "none",
								flexWrap: "inherit",
								flexDirection: "row",
								alignItems: "flex-start",
								justifyContent: "space-between",
								padding: "30px",
								gap: 0,
								overflowX: "auto",
								maxHeight: "100%",
							}}
						>
							<div className="project-details-content" ref={panelRef}>
								{renderDetailData()}
								<div className="panel-main-buttons-container">
									<a
										onClick={() => {
											navigate("project-settings", {
												replace: false,
												state: { ...projectInfo },
											});
										}}
										onContextMenu={(event: any) => {
											const location = `${window.location.href}/project-settings`;
											handleContextMenu(event, { link: location });
										}}
									>
										{GetAntIcon("setting")} Project settings
									</a>
									<a
										onClick={() => {
											setAuditOpen(true);
										}}
									>
										{GetAntIcon("history")} Audit log
									</a>
								</div>
							</div>

							<Divider
								type="vertical"
								style={{ height: "100%", margin: "0px 20px" }}
							></Divider>
							<div
								style={{
									width: "708px",
									background: "rgba(31,31,31,0.2)",
									borderRadius: "2px",
									padding: "0px 20px 10px 20px",
								}}
							>
								<div ref={teamRoleRef}>
									<Tabs items={[{ label: "Team roles", key: "team-roles" }]} />
								</div>

								<div
									id="table-container"
									style={{
										flex: 1,
										width: "100%",
										maxHeight: "100%",
										overflow: "hidden",
									}}
								>
									<Table
										rootClassName="m"
										pagination={false}
										scroll={{
											y:
												panelDimensions.height -
												teamRoleDimensions.height -
												64.8,
											x: 0,
										}}
										dataSource={filteredDataTable || dataTable}
										columns={[
											{
												title: (
													<SearchButton
														handleSearch={handleSearch}
														headerName="Role"
														columnKey="role"
														key={"y" + "role"}
													/>
												),
												dataIndex: "role",
												key: "role",
												render: (text: any, record: any) => {
													return (
														<>
															{setLookupData(
																context.allData,
																record,
																"team_roles",
																"role_id"
															)}
														</>
													);
												},
											},
											{
												title: (
													<SearchButton
														handleSearch={handleSearch}
														headerName="Assigned to"
														columnKey="appointee"
														key={"x" + "appointee"}
													/>
												),
												dataIndex: "appointee",
												render: (text: any, record: any) =>
													record["appointee"]?.map((name: any) => {
														return (
															<>
																<span>{name}</span>

																<br />
															</>
														);
													}),
												key: "appointee",
											},
										]}
									/>
								</div>
							</div>
						</div>
					</div>
					{/* Bottom Container */}
					<div
						className="main-container"
						style={{
							minHeight: "85%",
							flex: 1,
							width: "100%",
							// maxHeight: "100%",
							overflow: "hidden",
							display: "flex",
							flexDirection: "column",
						}}
					>
						<div className="project-header">
							<span>Map - Well location</span>
							<div
								style={{
									display: "flex",
									flex: "1",
									justifyContent: "flex-end",
									alignItems: "center",
									gap: "15px",
								}}
							>
								<Input
									placeholder="Search by operation"
									className="input-clearable"
									style={{ maxWidth: "265px" }}
									onPressEnter={(e: React.KeyboardEvent<HTMLInputElement>) => {
										setSearchValue(e.currentTarget.value);
										e.currentTarget.blur();
									}}
									onChange={(e) => {
										if (e.target.value === "") {
											setSearchValue("");
										}
									}}
									allowClear
									prefix={<SearchOutlined />}
								/>
								<div style={{ display: "flex", flexDirection: "row" }}>
									{" "}
									<div
										className={
											filterMode || hasSelectedFilters()
												? "project-active-button"
												: ""
										}
										style={{ padding: "4px 10px", cursor: "pointer" }}
										onClick={() => {
											setFilterMode(!filterMode);
										}}
									>
										{GetAntIcon("filter")}
									</div>
									{/* <div
								style={{ padding: "4px 10px", cursor: "pointer" }}
								onClick={() => {
									// setDescMode(!descMode);
								}}
							>
								{descMode ? GetAntIcon("descending") : GetAntIcon("ascending")}
							</div> */}
									{/* <div style={{ padding: "4px 10px", cursor: "pointer" }}>
								{GetAntIcon("setting")}
							</div> */}
								</div>
							</div>
						</div>
						<div className="project-overview-container overview-content">
							<div
								className={`filter-area ${
									filterMode ? "show-filter" : "hide-filter"
								}`}
							>
								{filterMode && (
									<>
										<CheckboxDropdown
											options={[
												{
													label: "onshore",
													value: "onshore",
												},
												{ label: "offshore", value: "offshore" },
											]}
											onChange={(selectedValues) =>
												handleMenuClick("location", selectedValues)
											}
											onClear={""}
											defaultValue={selectedFilters.location}
											Key="location"
											label="Location"
										/>
										<CheckboxDropdown
											options={context?.allData?.well_purpose?.map(
												(wp: any) => ({ label: wp.name, value: wp.uuid })
											)}
											onChange={(selectedValues) =>
												handleMenuClick("well_purpose", selectedValues)
											}
											onClear={""}
											defaultValue={selectedFilters["well_purpose"]}
											Key="well_purpose"
											label="Well purpose"
										/>
										<CheckboxDropdown
											options={context?.allData?.well_status?.map(
												(ws: any) => ({ label: ws.name, value: ws.uuid })
											)}
											onChange={(selectedValues) =>
												handleMenuClick("well_status", selectedValues)
											}
											onClear={""}
											defaultValue={selectedFilters["well_status"]}
											Key="well_status"
											label="Well status"
										/>
										<div className="test">
											<Button type="link" onClick={handleClearAllFilters}>
												Reset All
											</Button>
										</div>
									</>
								)}
							</div>
							<div className="overview-map">
								<Map
									projectList={filteredWellList || wellList}
									selectProject={selectWell}
									selectedProject={selectedWell}
									selectedProjectInfo={selectedWellInfo}
									keyType={"operation_code"}
								></Map>
								<div className="sub-container">
									{/* <div
										className="project-main-container"
										style={{ height: "90%", width: "100%" }}
									> */}
									<div className="project-list-container">
										{wellList?.length > 0 ? (
											<Space className="project-list" direction="vertical">
												{listWells()}
											</Space>
										) : (
											<Space className="project-list" direction="vertical">
												<span>This project does not have any wells yet.</span>
											</Space>
										)}
									</div>

									{/* </div> */}
								</div>
							</div>
						</div>
					</div>
					<AuditLogModal
						tenantKey={context?.currentTenantKey}
						identifierKeys={{ project_code: projectInfo?.project_code }}
						open={auditOpen}
						type={"project"}
						handleClose={() => {
							setAuditOpen(false);
						}}
					/>
				</div>
			)}
			{!projectInfo && !isLoading && (
				<>
					<NoAccess text={"This project does not exist."} />
				</>
			)}
		</>
	);
}

export default ProjectCampaignOverview;
