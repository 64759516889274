import axios from "axios";
import { url, AuthHeader } from "../../utils/_exports";
const apiURL = "tasks";

export const getTasks = (tenantKey: any, phase_id: any = null) => {
	return new Promise((resolve, reject) => {
		axios
			.get(`${url}/${tenantKey}/${apiURL}`, {
				...AuthHeader,
				params: {
					filter: {
						phase_id: phase_id,
					},
				},
			})
			.then((_data: any) => {
				if (_data?.data && _data.data.length) {
					resolve(_data.data);
				} else {
					resolve(null);
				}
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const addTaskItem = (tenantKey: any, data: any, user: any) => {
	// preset the status to 'New'
	data.status = "New";
	return new Promise((resolve, reject) => {
		axios
			.post(`${url}/${tenantKey}/${apiURL}/add`, { data, user }, AuthHeader)
			.then((_data: any) => {
				resolve(_data.data);
			})
			.catch((e: any) => {
				reject(e?.response?.data?.error);
			});
	});
};

export const updateTaskItem = (
	tenantKey: any,
	id: any,
	data: any,
	initialData: any,
	user: any,
	formList: any = null,
	extraAuditlog: any = null
) => {
	return new Promise((resolve, reject) => {
		axios
			.put(
				`${url}/${tenantKey}/${apiURL}/update`,
				{
					filter: { _id: id },
					data,
					initialData,
					user,
					formList,
					options: { upsert: true, new: true },
					extraAuditlog,
				},
				AuthHeader
			)
			.then((_data: any) => {
				resolve(_data.data);
			})
			.catch((e: any) => {
				reject(e?.response?.data?.error);
			});
	});
};

export const updateTaskDisabled = (
	tenantKey: any,
	id: any,
	data: any,
	user: any
) => {
	return new Promise((resolve, reject) => {
		axios
			.put(
				`${url}/${tenantKey}/${apiURL}/disable`,
				{
					filter: { _id: id },
					data,
					user,
					options: { upsert: true, new: true },
				},
				AuthHeader
			)
			.then((_data: any) => {
				resolve(_data.data);
			})
			.catch((e: any) => {
				reject(e?.response?.data?.error);
			});
	});
};

export const deleteTaskItem = (
	tenantKey: any,
	id: any,
	data: any,
	user: any
) => {
	return new Promise((resolve, reject) => {
		axios
			.post(
				`${url}/${tenantKey}/${apiURL}/delete`,
				{
					filter: {
						_id: id,
					},
					data,
					user,
				},
				{ ...AuthHeader }
			)
			.then((_data) => {
				resolve(true);
			})
			.catch((e: any) => {
				reject(e?.response?.data?.error);
			});
	});
};

export const getTask = (tenantKey: any, id: any) => {
	return new Promise((resolve, reject) => {
		if (!id) {
			resolve(null);
		} else {
			axios
				.get(`${url}/${tenantKey}/${apiURL}/task`, {
					params: {
						filter: {
							_id: id,
						},
					},
					...AuthHeader,
				})
				.then((_data: any) => {
					if (_data?.data) {
						resolve(_data.data);
					} else {
						resolve(null);
					}
				})
				.catch((e: any) => {
					reject(e);
				});
		}
	});
};
