import React from "react";
import PropTypes from "prop-types";
import { projectTest } from "../../utils/test";
import { Progress, Space } from "antd";
import { useLocation, useNavigate } from "react-router";
import { GetAntIcon } from "../../utils/ant_icons";
import Emitter from "../../utils/emitter";
import { countryList } from "../../utils/countryCodes";
import ProjectCards from "./ProjectCards";
import { superAdminRole } from "../../utils/_exports";
import { checkPrivilege } from "../../utils/utils";
import useHandleContextMenu from "../../hooks/useHandleContextMenu";

function Gallery(props: any) {
	const navigate = useNavigate();
	const { handleContextMenu } = useHandleContextMenu();

	const renderAllProjects = () => {
		let filteredProjects = props.allProjects
			.filter((project: any) => {
				const { searchValue, selectedFilters } = props;
				const {
					Type,
					SBU,
					Client,
					Region,
					Country,
					"Operating Office": OperatingOffice,
					Tags,
				} = selectedFilters;

				return (
					(searchValue === "" ||
						project.project_name
							.toLowerCase()
							.includes(searchValue.toLowerCase())) &&
					(Type.length === 0 || Type.includes(project.project_type)) &&
					(SBU.length === 0 || SBU.includes(project.business_unit)) &&
					(Client.length === 0 || Client.includes(project.client)) &&
					(Region.length === 0 || Region.includes(project.region)) &&
					(Country.length === 0 || Country.includes(project.country)) &&
					(OperatingOffice.length === 0 ||
						OperatingOffice.includes(project.operating_office)) &&
					((Array.isArray(Tags) && Tags.length === 0) ||
						(Array.isArray(Tags) &&
							Tags.some((tag) => project.project_tags.includes(tag))))
				);
			})
			.sort((a: any, b: any) => {
				if (!props.sort) {
					return a.project_name.localeCompare(b.project_name);
				} else {
					return b.project_name.localeCompare(a.project_name);
				}
			});

		return (
			<>
				{filteredProjects.map((project: any) => {
					return (
						<ProjectCards
							project={project}
							sort={props.sort}
							allProjects={props.allProjects}
							activeProjects={props.activeProjects}
							userRole={props.userRole}
							businessUnits={props.businessUnits}
							operatingOffices={props.operatingOffices}
							regions={props.regions}
							projectTags={props.projectTags}
							clients={props.clients}
							countries={countryList}
							setActiveProjects={props.setActiveProjects}
						/>
					);
				})}
			</>
		);
	};

	return (
		<div style={{ padding: "30px 86px", width: "100%" }}>
			<div
				className="gallery-container"
				style={{
					display: "grid",
					gridTemplateColumns: "repeat(4, minmax(22%, 1fr))",
					width: "100%",
					gap: "40px",
				}}
			>
				<>
					{checkPrivilege(props.userRole, ["tenant-admin"]) && (
						<div
							style={{
								alignSelf: "flex-start",
								height: "125px",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
							className="project-card project-add-button"
							onClick={() => {
								localStorage.setItem("currentProject", "Add new project");
								Emitter.emit("projectName", "Add new project");
								navigate("add-new-project", {
									replace: false,
								});
							}}
							onContextMenu={(e: any) => {
								const location = `${window.location.href}/add-new-project`;
								handleContextMenu(e, { link: location });
							}}
						>
							+ Add new project
						</div>
					)}

					{renderAllProjects()}
				</>
			</div>
		</div>
	);
}

export default Gallery;
