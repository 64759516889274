import React, { useEffect } from "react";
import { Space, Input, Tabs } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import Emitter from "../../utils/emitter";

const defaultMarkdownFile = require("./WellDeliveredNoContent.md");

const WellDelivered = (props: any) => {
	const [tabKey, setTabKey] = React.useState(props.params.contents[0].key);
	const [tab, setTab] = React.useState(props.params.contents[0]);

	const [markdownContent, setMarkdownContent] = React.useState("");

	useEffect(() => {
		const tabContent: any = props.params.contents.find(
			(item: any) => item.key === tabKey
		);
		fetch(tabContent.markdownContent)
			.then((res) => res.text())
			.then((text) => setMarkdownContent(text))
			.catch((error) => {
				console.error("Error fetching Markdown content:", error);
			});
	}, []);

	const handleTabChange = (key: any) => {
		setTabKey(key);
		setTab(props.params.contents.find((item: any) => item.key === key));
		const tabContent: any = props.params.contents.find(
			(item: any) => item.key === key
		);
		if (tabContent.descendants) {
			const descendant = tabContent.descendants[0];
			fetch(descendant.markdownContent)
				.then((res) => res.text())
				.then((text) => setMarkdownContent(text))
				.catch((error) => {
					console.error("Error fetching Markdown content:", error);
				});
		} else if (tabContent.markdownContent) {
			fetch(tabContent.markdownContent)
				.then((res) => res.text())
				.then((text) => setMarkdownContent(text))
				.catch((error) => {
					console.error("Error fetching Markdown content:", error);
				});
		} else {
			fetch(defaultMarkdownFile)
				.then((res) => res.text())
				.then((text) => setMarkdownContent(text))
				.catch((error) => {
					console.error("Error fetching Markdown content:", error);
				});
		}
	};

	const handleExtraTabChange = async (key: any) => {
		const descendant = tab.descendants.find((item: any) => item.key === key);
		fetch(descendant.markdownContent)
			.then((res) => res.text())
			.then((text) => setMarkdownContent(text))
			.catch((error) => {
				console.error("Error fetching Markdown content:", error);
			});
	};

	const markdownStyles = {
		Heading: ({ children }: any) => <span className="header">{children}</span>,
		Paragraph: ({ children }: any) => <p className="paragraph">{children}</p>,
		UnorderedList: ({ children }: any) => (
			<ul className="unordered-list">{children}</ul>
		),
		OrderedList: ({ children }: any) => (
			<ol className="ordered-list">{children}</ol>
		),
	};

	return (
		<div
			className="generic-container well-delivered-page"
			style={{ gap: "20px" }}
		>
			<Space direction="vertical">
				<span className="title">{props.params.propTitle}</span>
			</Space>
			<div className="main-container">
				<div className="generic-header">Guides</div>
				<div className="generic-content">
					<Tabs
						style={{ lineHeight: "1.55" }}
						tabPosition="left"
						onChange={handleTabChange}
						activeKey={tabKey}
						items={props.params.contents}
					/>
					<div className="markdown-content">
						{tab.descendants ? (
							<>
								<Tabs items={tab.descendants} onChange={handleExtraTabChange} />
								<ReactMarkdown
									children={markdownContent}
									components={{
										h1: markdownStyles.Heading,
										p: markdownStyles.Paragraph,
										ul: markdownStyles.UnorderedList,
										ol: markdownStyles.OrderedList,
									}}
									rehypePlugins={[rehypeRaw]}
								/>
							</>
						) : (
							<ReactMarkdown
								children={markdownContent}
								components={{
									h1: markdownStyles.Heading,
									p: markdownStyles.Paragraph,
									ul: markdownStyles.UnorderedList,
									ol: markdownStyles.OrderedList,
								}}
								rehypePlugins={[rehypeRaw]}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default WellDelivered;
