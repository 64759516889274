import axios from "axios";
import { url, AuthHeader } from "../../utils/_exports";
import { TurnedIn } from "@mui/icons-material";
const apiURL = "discussion"; // Adjust if needed based on your API structure

// Get comments for a specific item
export const getComments = (tenantKey: any, taskId: any) => {
	return new Promise((resolve, reject) => {
		const requestURL = `${url}/${tenantKey}/${apiURL}`;
		console.log("`getComments` API's Request URL: ", requestURL);

		axios
			.get(
				requestURL,
				{
					params: {
						filter: {
							task_id: taskId, // Adjust this parameter as needed
						},
					},
					...AuthHeader,
				}
			)
			.then((_data: any) => {
				if (_data?.data && _data.data.length) {
					resolve(_data.data);
				} else {
					resolve(null);
				}
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

// Exports the addComment function so it can be imported and used elsewhere in your code
export const addComment = (tenantKey: any, task_id: any, user: any, commentData: any) => {
	// Returns a new Promise. A promise is an object that represents the eventual completion (or failure) of an asynchronous operation
	return new Promise(
		// If the operation is successful, we call resolve()
		// If it fails, we call reject()
		(resolve, reject) => {
		// Constructs the URL for the API request by concatenating url, tenantKey, and apiURL, with /add at the end
		const requestURL = `${url}/${tenantKey}/${apiURL}/add`;
		// Log the constructed URL into console
		console.log("`addComment` API's Request URL:", requestURL);
		
		axios
			.post(
				requestURL, // Use constructed URL
                { tenant_key: tenantKey, task_id, user, comment: commentData }, // Include tenant_key
				AuthHeader // authorization header used to provide authentication information
			)
			.then((_data: any) => {
				// Signals the promise has been resolved (successful operation) and passes the data to the function that called `addComment`
				resolve(_data.data);
			})
			.catch((e: any) => {
				// It drills down to e.response.data.error to retrieve a specific error message from the response
				reject(e?.response?.data?.error);
			});
	});
};

// Exports the editComment function so it can be imported and used elsewhere in your code
export const editComment = (tenantKey: any, task_id: any, user: any, commentId: any, updatedCommentData: any) => {
	// Returns a new Promise. A promise is an object that represents the eventual completion (or failure) of an asynchronous operation
	return new Promise(
		// If the operation is successful, we call resolve()
		// If it fails, we call reject()
		(resolve, reject) => {
		// Constructs the URL for the API request by concatenating url, tenantKey, and apiURL, with /edit/<commentId> at the end
		const requestURL = `${url}/${tenantKey}/${apiURL}/edit/${commentId}`;
		// Log the constructed URL into console
		console.log("`editComment` API's Request URL:", requestURL);
		
		axios
			.put(
				requestURL, // Use constructed URL for PUT request
				{ tenant_key: tenantKey, task_id, user, comment: updatedCommentData }, // Include updated comment data
				AuthHeader // authorization header used to provide authentication information
			)
			.then((_data: any) => {
				// Signals the promise has been resolved (successful operation) and passes the data to the function that called `editComment`
				resolve(_data.data);
			})
			.catch((e: any) => {
				// It drills down to e.response.data.error to retrieve a specific error message from the response
				reject(e?.response?.data?.error);
			});
	});
};

export const deleteComment = (tenantKey: any, user: any, commentId: any) => {
	return new Promise((resolve, reject) => {
		// Constructs the URL for the API request by concatenating `url`, `tenantKey`, and `apiURL`, with `/delete` at the end
		const requestURL = `${url}/${tenantKey}/${apiURL}/delete`;
		// Log the constructed URL into console
		console.log("`deleteComment` API's Request URL:", requestURL);

		const data = {
            filter: {
                _id: commentId,
            },
            user,
        }

		axios
			.delete(
				requestURL, // Use constructed URL
				{
					data, // Pass data in the request body
					...AuthHeader, // Include your AuthHeader here
				},
			)
			.then((_data: any) => {
				// Signals the promise has been resolved (successful operation) and passes the data to the function that called `deleteComment`
				resolve(_data.data);
			})
			.catch((e: any) => {
				// It drills down to e.response.data.error to retrieve a specific error message from the response
				reject(e?.response?.data?.error);
			});
		}
	)
}