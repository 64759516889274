import axios from "axios";
import { url, AuthHeader, superAdminRole } from "../../utils/_exports";
const apiURL = "tenant-management";

export const getTenantList = () => {
	return new Promise((resolve, reject) => {
		axios
			.get(`${url}/${apiURL}`, {
				...AuthHeader,
			})
			.then((_data: any) => {
				if (_data?.data && _data.data.length) {
					resolve(_data.data);
				} else {
					resolve(null);
				}
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const getSpecificTenants = (userRole: any, role_keys: any) => {
	return new Promise((resolve, reject) => {
		let params = { filter: {} };
		if (!userRole.includes(superAdminRole)) {
			params = { filter: { role_key: { $in: role_keys } } };
		}
		axios
			.get(`${url}/${apiURL}`, {
				params,
				...AuthHeader,
			})
			.then((_data: any) => {
				if (_data?.data && _data.data.length) {
					resolve(_data.data);
				} else {
					resolve(null);
				}
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const addTenantItem = (data: any) => {
	return new Promise((resolve, reject) => {
		axios
			.post(`${url}/${apiURL}`, data, AuthHeader)
			.then((_data: any) => {
				resolve(_data.data);
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const updateTenantItem = (uuid: any, data: any) => {
	return new Promise((resolve, reject) => {
		//
		axios
			.put(
				`${url}/${apiURL}`,
				{
					filter: { uuid: uuid },
					data: data,
					options: { upsert: true, new: true },
				},
				AuthHeader
			)
			.then((_data: any) => {
				resolve(_data.data);
			})
			.catch((e: any) => {
				//
				reject(e);
			});
	});
};
