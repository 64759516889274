import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Drawer, Table, Typography, Tag, Space } from "antd";
import SearchButton from "../SearchButton";
import { GetAntIcon } from "../../utils/ant_icons";
import "../../assets/scss/auditlog.scss";
import { getAuditLogList } from "../../services/api-server/audit_logs";
import dayjs from "dayjs";

function AuditLogModal(props: any) {
	const tenantKey = props.tenantKey;
	const open = props.open;
	const type = props.type;
	const label =
		props.label || props.type.charAt(0).toUpperCase() + props.type.slice(1);
	const handleClose = props.handleClose;
	const identifierKeys = props.identifierKeys;
	const auditlogFilter: Array<any> = props.auditlogFilter;

	const [auditData, setAuditData] = useState<any>([]);
	const [filteredDataTable, setFilteredDataTable] = useState<any>(null);
	const [tempRecord, setTempRecord] = useState<any>(null);
	const [searchRecord, setSearchRecord] = useState<any>([]);

	useEffect(() => {
		getAuditLogList(tenantKey, identifierKeys, type, auditlogFilter)
			.then((data: any) => {
				setAuditData(data || []);
			})
			.catch((error: any) => {});
	}, [type, identifierKeys]);

	const handleSearch = (record: any) => {
		setTempRecord(record);
	};

	useEffect(() => {
		if (searchRecord && tempRecord) {
			let recordIndex = searchRecord.findIndex((item: any) => {
				return item.columnKey === tempRecord.columnKey;
			});
			if (recordIndex !== -1) {
				if (tempRecord.value != "") {
					let _record = [...searchRecord];
					_record[recordIndex] = tempRecord;
					setSearchRecord(_record);
				} else {
					let _record = [...searchRecord];
					_record.splice(recordIndex, 1);
					setSearchRecord(_record);
				}
			} else {
				if (tempRecord.value != "") {
					let _record = [...searchRecord];
					_record.push(tempRecord);
					setSearchRecord(_record);
				}
			}
		}
	}, [tempRecord]);

	useEffect(() => {
		if (searchRecord?.length > 0) {
			let _data = [...auditData];
			const filteredData = _data?.filter((item: any) => {
				return searchRecord.every((record: any) => {
					if (Array.isArray(item[record.columnKey])) {
						return item[record.columnKey].some((arrayItem: any) => {
							if (typeof arrayItem === "object") {
								return Object.values(arrayItem).some((value: any) => {
									if (Array.isArray(value)) {
										return value.some((innerValue) =>
											innerValue
												?.toString()
												.toLowerCase()
												.includes(record.value.toString().toLowerCase())
										);
									} else {
										return value
											?.toString()
											.toLowerCase()
											.includes(record.value.toString().toLowerCase());
									}
								});
							} else {
								return arrayItem
									.toString()
									.toLowerCase()
									.includes(record.value.toString().toLowerCase());
							}
						});
					} else {
						return item[record.columnKey]
							.toString()
							.toLowerCase()
							.includes(record.value.toString().toLowerCase());
					}
				});
			});
			setFilteredDataTable(filteredData);
		} else {
			setFilteredDataTable(null);
		}
	}, [searchRecord]);

	const displayArray = (data: any) => {
		return (
			<>
				<ul>
					{data.map((item: any) => {
						{
							typeof item === "object"
								? (item = "Can't show object data")
								: (item = item);
						}
						return <li>{item}</li>;
					})}
				</ul>
			</>
		);
	};

	return (
		<Drawer
			title={`Audit log - ${label} details`}
			open={open}
			closeIcon={GetAntIcon("rightarrow")}
			onClose={handleClose}
			width={"50vw"}
			zIndex={9999}
		>
			<Table
				rootClassName="audit-log-table"
				style={{ width: "100%" }}
				bordered={true}
				dataSource={filteredDataTable || auditData}
				columns={[
					{
						title: "Date",
						dataIndex: "created_on",
						key: "Created date",
						width: 200,
						render: (value: any) => {
							return dayjs(value).format("DD MMM YYYY hh:mm:ss A");
						},
						sorter: (a: any, b: any) => {
							let dateA = new Date(a.created_on);
							let dateB = new Date(b.created_on);
							if (dateA < dateB) return -1;
							else if (dateA > dateB) return 1;
							else return 0;
						},
					},
					{
						title: (
							<SearchButton
								buttonType="link"
								handleSearch={handleSearch}
								headerName="User"
								columnKey="created_by"
								key={"createdByx"}
							/>
						),
						dataIndex: "created_by",
						key: "created_by",
						width: 200,
					},
					{
						title: (
							<SearchButton
								buttonType="link"
								handleSearch={handleSearch}
								headerName="Event"
								columnKey="event"
								key={"event"}
							/>
						),
						dataIndex: "event",
						key: "event name",
						width: 200,
					},
					{
						title: (
							<SearchButton
								buttonType="link"
								handleSearch={handleSearch}
								headerName="Details"
								columnKey="details"
								key={"key" + "details"}
							/>
						),
						dataIndex: "details",
						key: "details",
						width: 200,
						render: (value: any) => {
							return value.map((item: any, index: any) => {
								return (
									<>
										<Space direction="vertical">
											{item?.event && (
												<Tag
													className="event-tag"
													style={{
														whiteSpace: "normal",
														wordWrap: "break-word",
														borderRadius: "2px",
														border: "1px solid rgba(255,255,255,0.3)",
														background: "rgba(113, 113, 113, 0.50)",
														color: "rgba(255, 255, 255, 0.85",
														margin: "2px",
													}}
												>
													{item.event}
												</Tag>
											)}
											{item?.new_data && (
												<Typography.Text>
													{Array.isArray(item?.new_data)
														? displayArray(item?.new_data)
														: typeof item?.new_data === "object"
														? JSON.stringify(item?.new_data)
														: item?.new_data}
												</Typography.Text>
											)}
											{item?.old_data && (
												<Typography.Text delete>
													{Array.isArray(item?.old_data)
														? displayArray(item?.old_data)
														: typeof item?.old_data === "object"
														? JSON.stringify(item?.old_data)
														: item?.old_data}
												</Typography.Text>
											)}
										</Space>
									</>
								);
							});
						},
					},
				]}
			></Table>
		</Drawer>
	);
}

export default AuditLogModal;
