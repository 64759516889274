import { useContext, useEffect, useRef, useState } from "react";
import { HomeContext } from "./Home";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom"; // Import the Link component
import {
	Button,
	Divider,
	Empty,
	Flex,
	Form,
	Input,
	Modal,
	Popconfirm,
	Space,
	Table,
	Tag,
	Tooltip,
	Upload,
	Avatar,
} from "antd";
import { GetAntIcon } from "../utils/ant_icons";
import elementalSvg from "../assets/image/elemental 'e'.svg";
import { getTask, updateTaskItem } from "../services/api-server/tasks";
import {
	getDeliverables,
	updateDeliverableItem,
} from "../services/api-server/deliverables";
import { setLookupData } from "../utils/lookupFinder";
import { getProject } from "../services/api-server/projects";
import { getPhaseItem } from "../services/api-server/phases";
import { DownloadOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { checkPrivilege, checkProjectTeam } from "../utils/utils";
import Emitter from "../utils/emitter";
import { useForm } from "antd/es/form/Form";
import {
	fileDownload,
	fileRemove,
	fileUpload,
} from "../services/api-server/files";
import { v4 } from "uuid";
import { excludedMark, statusIcon } from "../utils/statusIcon";
import SearchButton from "../components/SearchButton";
import { io } from "socket.io-client";
import { socket } from "../utils/socket";
import dayjs, { Dayjs } from "dayjs";
import AuditLogModal from "../components/Modal/AuditLogModal";
import { getCrudaClass } from "../utils/lookup_list";
import useWindowDimensions from "../hooks/useWindowDimensions";
import FormButtonSave from "../components/FormButtonSave";
import CustomForm from "../components/CustomForm";
import useContainerDimensions from "../hooks/useContainerDimensions";
import { getOperations } from "../services/api-server/operations";
import { active } from "d3";
import { circle } from "leaflet";
/* Import APIs from `dicussion.ts` */
import {
	getComments,
	addComment,
	editComment,
	deleteComment,
} from "../services/api-server/discussion";
/* Text Editor */
import TextEditor from "../components/TextEditor";
import { getAllUsers } from "../services/api-server/user";

// Define the interface for the text editor handle
interface TextEditorHandle {
	clearEditor: () => void; // Method to clear the editor

	getEditor: () => {
		root: {
			innerHTML: string;
		};
	};
}

function TaskOverview(props: any) {
	const context: any = useContext(HomeContext);
	const location = useLocation();
	const navigate = useNavigate();

	const [projectTitle, setProjectTitle] = useState<any>(null);
	const [project, setProject] = useState<any>(null);
	const [wellTitle, setWellTitle] = useState<any>(null);
	const [phaseTitle, setPhaseTitle] = useState<any>(null);
	const [taskInfo, setTaskInfo] = useState<any>(null);
	const [taskDetailsExpand, setTaskDetailsExpand] = useState(false);
	const [activePage, setActivePage] = useState<any>("deliverables");
	const [deliverableList, setDeliverableList] = useState<any>([]);
	const [filteredDeliverableList, setFilteredDeliverableList] =
		useState<any>(null);
	const [sideContainerOpen, setSideContainerOpen] = useState<any>(false);
	const [sideContainerData, setSideContainerData] = useState<any>(null);
	const [deliverableKey, setDeliverableKey] = useState<any>(null);
	const [tempRecord, setTempRecord] = useState<any>(null);
	const [searchRecord, setSearchRecord] = useState<any>([]);
	const [project_code, setProjectCode] = useState<any>(null);
	const [operationCode, setOperationCode] = useState<any>(null);
	const [phase_id, setPhaseId] = useState<any>(null);
	const [task_id, setTaskId] = useState<any>(null);
	const [responsibleParty, setResponsibleParty] = useState<any>([]);
	const [reopenModal, setReopenModal] = useState<any>(false);
	const [cancelPopover, setCancelPopover] = useState<any>(false);
	const [formReset, setFormReset] = useState<boolean>(true);

	const [tableRef, tableDimensions] = useContainerDimensions();

	const [allUsers, setAllUsers] = useState<any>(null);

	interface User {
		_id: string;
		name: string;
		email: string;
		theme: string;
		__v: number;
		country_code: string;
		phone_number: string;
		profile_picture: string;
	}

	const commentingUser = (email: any) => {
		// Find if there is a user with a name in the MongoDB document by comparing this current active user to users within the document
		const commentingUser = allUsers?.filter(
			(user: User) => user.email === email
		);
		// If a document(JSON object) regarding the user is found and if the document is not empty
		if (commentingUser && commentingUser.length > 0) {
			// Return the document as an (JSON object)
			return commentingUser[0];
		} else {
			return null;
		}
	};

	// Hide Components Handling
	const [componentsHide, setComponentsHide] = useState<any>(true);

	const containerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				containerRef.current &&
				!containerRef.current.contains(event.target as Node)
			) {
				setComponentsHide(true);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [containerRef]);

	/* 
		`any[]` means the array can contain elements of any data type
		`([])` The initial state is set to [], an empty array.
	*/
	// Multiple Comments Array Display Handling
	const [comments, setComments] = useState<any[]>([]);
	const [newComment, setNewComment] = useState<any>(null);
	// Text Editor Handling
	const editorRef = useRef<TextEditorHandle | null>(null); // Define the ref type

	// Call `loadComments` function when component mounts
	useEffect(() => {
		// If `task_id` exists
		if (context.currentTenantKey && task_id) {
			// Run `loadComments` function
			loadComments(
				// Pass current tenant's identifier and `task_id`
				context.currentTenantKey,
				task_id
			);
		}
		// This `useEffect` hook runs when the content of `task_id` changes
	}, [context.currentTenantKey, task_id]);

	// Takes `tenantKey` and `taskId` as arguments of any data type
	const loadComments = (tenantKey: any, taskId: any) => {
		// A client-side API key that fetches the comments
		getComments(tenantKey, taskId)
			// When the Promise is resolved successfully, .then() allows you to define what should happen next with the result
			.then((data) => {
				// If the `data` being passed through is an array
				if (Array.isArray(data)) {
					// `setComments` with data
					setComments(data);
				} else {
					console.error("Unexpected response structure:", data);
					// `setComments` as an empty array if data is not there
					setComments([]);
				}
				console.log("Comments loaded successfully");
			})
			// If the Promise is rejected (due to an error), you can handle it using .catch()
			.catch((e) => {
				console.error("Error loading comments:", e);
			});
	};

	// Add Comment Button Disabled Handling
	const [addButtonDisabled, setAddButtonDisabled] = useState<any>(true);

	useEffect(() => {
		const editorContent = editorRef.current?.getEditor()?.root.innerHTML || ""; // Get HTML content
		const textContent = editorContent.replace(/<[^>]+>/g, ""); // Remove all HTML tags
		const trimmedContent = textContent.trim(); // Trim whitespace

		const isCommentValid = trimmedContent.length > 0; // Check if there is any text
		setAddButtonDisabled(!isCommentValid); // Enable or disable button based on text presence
	}, [newComment]); // Run this effect when newComment changes

	// Add Comment Handling
	const handleAddComment = async () => {
		// Use the raw HTML content directly without trimming
		const originalComment = newComment; // Keep all formatting

		// Make sure that both itemId and currentUser are available
		if (!task_id) {
			console.error("Task ID is missing");
			return;
		}

		if (!context.currentTenantKey) {
			console.error("User is missing");
			return;
		}

		// Check for empty comment (only whitespace)
		if (originalComment.trim() === "") return;

		// Comment Details
		console.log("Tenant Key: ", context.currentTenantKey);
		console.log("Task ID: ", task_id);
		console.log("Username: ", props.params.user.email);
		console.log("New Comment: ", originalComment);

		try {
			// Call the `addComment` API with the raw HTML
			const data = await addComment(
				context.currentTenantKey,
				task_id,
				props.params.user.email,
				originalComment
			);

			// Update comments state
			setComments((prev) => [...prev, data]);
			setNewComment(""); // Clear the input field
			editorRef.current?.clearEditor(); // Clear the TextEditor content

			console.log("Comment added successfully");
		} catch (error) {
			console.error("Error adding comment:", error);
		}
	};

	// Edit Comment Initialization Handling
	const [editCommentClick, setEditCommentClick] = useState<any>(false);
	const [editCommentID, setEditCommentID] = useState<any>(null);
	const [editCommentContent, setEditCommentContent] = useState<any>(null);

	const [commentCompare, setCommentCompare] = useState<any>(null);

	const handleEditInit = (editCommentID: any, editCommentContent: any) => {
		// Handle the Clicking of Edit Button
		setEditCommentClick(!editCommentClick);
		// Handle the storing of Selected Comment ID
		setEditCommentID(editCommentID);

		// Handle the storing of Selected Comment
		setEditCommentContent(editCommentContent);
		// Handle the storing of New Comment
		setCommentCompare(editCommentContent);
	};

	// Edit Comment Handling
	const [editButtonDisabled, setEditButtonDisabled] = useState<any>(true);

	// Edit Button Disabled Handling
	useEffect(() => {
		const editorContent = editorRef.current?.getEditor()?.root.innerHTML || ""; // Get HTML content
		const textContent = editorContent.replace(/<[^>]+>/g, ""); // Remove all HTML tags
		const trimmedContent = textContent.trim(); // Trim whitespace

		const isCommentNotEmpty = trimmedContent.length !== 0; // Check if there is any text
		//console.log("Comment Length: ", isCommentNotEmpty);
		const isCommentDifferent = editCommentContent !== commentCompare; // Check if the new comment is different from the old one
		//console.log("Comment Comparison: ", isCommentDifferent);

		// Disable the button if the comment is empty or the new comment is the same as the old one
		setEditButtonDisabled(isCommentNotEmpty && isCommentDifferent);
		//console.log("Edit Button Disabled: ", editButtonDisabled);
	}, [editCommentClick, editCommentContent, commentCompare]); // Run this effect when editCommentContent or commentCompare changes

	const handleEditComment = async (
		commentId: any,
		editCommentContent: any,
		comment: any
	) => {
		console.log("Selected Comment ID:", commentId);
		console.log("Selected Comment:", editCommentContent);

		// Use the raw HTML content directly without trimming
		const updatedComment = editCommentContent; // Keep all formatting

		// Make sure that both task_id and currentTenantKey are available
		if (!task_id) {
			console.error("Task ID is missing");
			return;
		}

		if (!context.currentTenantKey) {
			console.error("Tenant Key is missing");
			return;
		}

		// Check for empty comment (only whitespace)
		if (updatedComment.trim() === "") {
			console.error("Comment cannot be empty");
			return;
		}

		// Comment Details
		console.log("Selected Comment Details ");
		console.log("Tenant Key: ", context.currentTenantKey);
		console.log("Task ID: ", task_id);
		console.log("User: ", comment.user);
		console.log("Comment ID: ", commentId);
		console.log("Updated Comment: ", updatedComment);

		try {
			// Call the `editComment` API with the updated comment data
			const data = await editComment(
				context.currentTenantKey,
				task_id,
				comment.user,
				commentId,
				updatedComment
			);
			console.log(data);

			const _comments = [...comments];
			const check = _comments.map((comment) => {
				console.log(comment);
				return comment._id === commentId ? data : comment;
			});
			console.log(check);

			// Update the comments state by replacing the edited comment
			setComments((prev) =>
				prev.map((comment) => {
					console.log(comment);
					return comment._id === commentId ? data : comment;
				})
			);
			console.log("Updated comments before setting state: ", comments);

			setNewComment(null);
			/* HandleEditInit */
			setEditCommentClick(false);
			setEditCommentID(null);
			setCommentCompare(null);

			editorRef.current?.clearEditor(); // Clear the TextEditor content

			console.log("Comment edited successfully");
		} catch (error) {
			console.error("Error editing comment:", error);
		}
	};

	const [deleteCommentClick, setDeleteCommentClick] = useState<any>(false);
	const [deleteCommentID, setDeleteCommentID] = useState<any>(null);

	const handleDeleteInit = (deleteCommentID: any) => {
		// Handle the Clicking of Delete Button
		setDeleteCommentClick(!deleteCommentClick);

		// Handle the storing of Selected Comment ID
		setDeleteCommentID(deleteCommentID);
	};

	// Delete Comment Handling
	const handleDeleteComment = async (commentId: any) => {
		console.log("Tenant Key: ", context.currentTenantKey);
		console.log("Comment ID: ", commentId);

		try {
			// Call the deleteComment API function with the correct commentId
			const data = await deleteComment(
				context.currentTenantKey,
				context.currentTenantKey,
				commentId
			);

			console.log(data);

			// Filter out the deleted comment from the state
			const updatedComments = comments.filter(
				(comment) => comment._id !== commentId
			);
			setComments(updatedComments);

			console.log("Comment deleted successfully");
		} catch (error) {
			console.error("Error deleting comment: ", error);
		}
	};

	useEffect(() => {
		const _responsibleParty = project?.role_assignments.filter((role: any) =>
			taskInfo.responsible_roles.includes(role.role_id)
		);
		setResponsibleParty(_responsibleParty);
	}, [taskInfo]);

	// 	Audit Logs states
	const [auditOpen, setAuditOpen] = useState<any>(false);
	const [auditDeliverableOpen, setAuditDeliverableOpen] = useState<any>(false);
	const [auditData, setAuditData] = useState<any>([]);

	const [form] = Form.useForm();
	const [reopenForm] = Form.useForm();

	const mapRolesData = (data: any, extraData: any) => {
		let role_assignments_data =
			extraData?.roles?.map((element: any) => {
				return { label: element.name, value: element._id };
			}) || [];
		role_assignments_data.push({
			label: "Project manager",
			value: "project_manager",
		});

		return (
			role_assignments_data
				.filter((roles: any) => data.includes(roles.value))
				?.map((obj: any) => obj.label) || []
		);
	};

	const loadData = (
		project_code: any,
		operation_code: any,
		phase_id: any,
		task_id: any,
		deliverable_id: any,
		extraData: any
	) => {
		Emitter.emit("loading", true);
		Promise.all([
			getProject(context.currentTenantKey, project_code),
			getOperations(context.currentTenantKey, {
				project_code,
				operation_code,
			}),
			getPhaseItem(context.currentTenantKey, phase_id),
			getTask(context.currentTenantKey, task_id),
			getDeliverables(context.currentTenantKey, task_id),
			getAllUsers(),
		])
			.then((values: any) => {
				setProjectTitle(values[0]?.project_name);
				context.setCurrentProject(values[0]);
				setWellTitle(values[1][0]?.well_project_name);
				context.setCurrentOperation(values[1][0]);
				setPhaseTitle(values[2][0]?.phase_name);
				context.setCurrentPhase(values[2][0]);
				setTaskInfo(values[3]);
				context.setCurrentTask(values[3]);

				// from values[4] which is the deliverable data, create a new field which is responsible_roles_names, accountable_roles_names, consulted_names, informed_parties_names based on the id of the respective roles field already exists
				values[4]
					//TO BE REMOVED
					?.sort((a: any, b: any) => a.created_on.localeCompare(b.created_on))
					.map((deliverable: any) => {
						deliverable.assigned_to_names = mapRolesData(
							deliverable.assigned_to,
							extraData
						);
						deliverable.reviewed_by_names = mapRolesData(
							deliverable.reviewed_by,
							extraData
						);
					});

				setAllUsers(values[5]);
				console.log(values[5]);

				setDeliverableList(values[4]);
				Emitter.emit("loading", false);

				if (sideContainerData?._id) {
					const newSideContainerData = values[4].find((item: any) => {
						return item._id === sideContainerData._id;
					});
					//this thing will re-render the useEffect
					setSideContainerData(newSideContainerData);
					setSideContainerOpen(true);
				} else if (deliverable_id) {
					const newSideContainerData = values[4].find((item: any) => {
						return item._id === deliverable_id;
					});
					//this thing will re-render the useEffect
					setSideContainerData(newSideContainerData);
					setSideContainerOpen(true);
				}
			})
			.catch((e: any) => {
				// setIsLoading(false);
				Emitter.emit("loading", false);
			})
			.finally(() => {
				// calculateTableHeight();
				Emitter.emit("loading", false);
			});
	};

	useEffect(() => {
		socket.on("REFRESH_DATA", () => {
			loadData(
				project_code,
				operationCode,
				phase_id,
				task_id,
				deliverableKey,
				context.allData
			);
			Emitter.emit("alert", {
				type: "success",
				message: `Data refreshed based on latest update.`,
				description: "",
				top: true,
				closeable: false,
				timeout: 3000,
			});
		});
		return () => {
			socket.removeListener("REFRESH_DATA", () =>
				loadData(
					project_code,
					operationCode,
					phase_id,
					task_id,
					deliverableKey,
					context.allData
				)
			);
		};
	}, [
		sideContainerData,
		project_code,
		operationCode,
		phase_id,
		task_id,
		deliverableKey,
	]);

	useEffect(() => {
		Emitter.emit("loading", true);
		if (context.currentTenantKey && context.allData) {
			let project_code: any =
				location?.state?.project_code || location?.pathname?.split("/")[2];
			let operation_code: any =
				location?.state?.operation_code || location?.pathname?.split("/")[3];
			let phase_id: any =
				location?.state?.phase_id || location?.pathname?.split("/")[4];
			let task_id: any =
				location.state?.task_id || location?.pathname?.split("/")[5];

			let deliverable_id: any = location.state?.deliverable_id;
			let foundProject = context.allData?.projects?.find(
				(project: any) => project?.project_code === project_code
			);

			setProjectCode(project_code);
			setOperationCode(operation_code);
			setDeliverableKey(deliverable_id);
			setPhaseId(phase_id);
			setTaskId(task_id);

			// Have to handle archive
			if (foundProject) {
				setProjectTitle(foundProject.project_name);
				setProject(foundProject);
			}
			loadData(
				project_code,
				operationCode,
				phase_id,
				task_id,
				deliverable_id,
				context.allData
			);
		}
	}, [context.currentTenantKey, context.allData]);

	// Searching records
	useEffect(() => {
		if (searchRecord && tempRecord) {
			let recordIndex = searchRecord.findIndex((item: any) => {
				return item.columnKey === tempRecord.columnKey;
			});
			if (recordIndex !== -1) {
				if (tempRecord.value != "") {
					let _record = [...searchRecord];
					_record[recordIndex] = tempRecord;
					setSearchRecord(_record);
				} else {
					let _record = [...searchRecord];
					_record.splice(recordIndex, 1);
					setSearchRecord(_record);
				}
			} else {
				if (tempRecord.value != "") {
					let _record = [...searchRecord];
					_record.push(tempRecord);
					setSearchRecord(_record);
				}
			}
		}
	}, [tempRecord]);

	// Filter data and table columns based on search record
	useEffect(() => {
		let data: any = Array.isArray(deliverableList) ? [...deliverableList] : [];
		if (searchRecord?.length > 0) {
			let filteredData = data
				?.map((element: any) => {
					let bool = searchRecord.every((item: any) => {
						if (Array.isArray(element[item.columnKey])) {
							return element[item.columnKey].some((arrayItem: any) =>
								arrayItem.toLowerCase().includes(item.value.toLowerCase())
							);
						} else {
							return element[item.columnKey]
								?.toLowerCase()
								.includes(item.value.toLowerCase());
						}
					});
					if (bool) return element;
				})
				.filter((element: any) => element !== null && element !== undefined);
			setFilteredDeliverableList(filteredData);
			data = filteredData;
		} else {
			setFilteredDeliverableList(null);
		}
	}, [searchRecord]);

	const setTaskPanelContent = (taskInfo: any, key: any) => {
		let content = "-";
		// if key exists in taskInfo update content
		if (taskInfo && taskInfo[key]) {
			if (
				[
					"responsible_roles",
					"accountable_roles",
					"consulted",
					"informed_parties",
				].includes(key)
			) {
				content = setRoles(taskInfo, key);
			} else {
				content = taskInfo[key];
			}
		}
		return content;
	};

	const renderMetaData = () => {
		return (
			<>
				{/* Task details container */}
				<div
					style={{
						display: "flex",
						// flexWrap: "wrap",
						gap: "20px",
						rowGap: "20px",
					}}
				>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							gap: "20px",
							minWidth: "30%",
						}}
					>
						{/* Task title */}
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								width: "100%",
							}}
						>
							<div className="panel-detail-label">Task title</div>
							<div className="panel-item-content">
								{setTaskPanelContent(taskInfo, "task_name")}
							</div>
						</div>

						{/* Status */}
						<div
							style={{ display: "flex", flexDirection: "column", width: "30%" }}
						>
							<div className="panel-detail-label">Status</div>
							<div className="panel-item-content">
								<>
									{taskInfo?.closed ? (
										<>Closed</>
									) : (
										<>
											{setTaskPanelContent(taskInfo, "status") !== "Late" ? (
												setTaskPanelContent(taskInfo, "status")
											) : (
												<div style={{ display: "flex", gap: "3px" }}>
													{setTaskPanelContent(taskInfo, "status")}{" "}
													<Tooltip
														title={
															<Flex vertical gap={20}>
																<Flex vertical>
																	<div>
																		<b>Reason:</b>
																	</div>
																	<div>
																		{setTaskPanelContent(taskInfo, "blocker")}
																	</div>
																</Flex>
																<Flex vertical>
																	<div>
																		<b>Plan:</b>
																	</div>
																	<div>
																		{setTaskPanelContent(taskInfo, "plan")}
																	</div>
																</Flex>
															</Flex>
														}
														placement="right"
													>
														<div style={{ color: "rgba(255,255,255,0.45)" }}>
															{GetAntIcon("question3")}
														</div>
													</Tooltip>
												</div>
											)}
										</>
									)}
								</>
							</div>
						</div>

						{/* Objective */}
						<div style={{ display: "flex", flexDirection: "column" }}>
							<div className="panel-detail-label">Objective</div>
							<div className="panel-item-content">
								{setTaskPanelContent(taskInfo, "objective")}
							</div>
						</div>
					</div>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							gap: "20px",
							minWidth: "70%",
						}}
					>
						{/* Notes */}
						<div style={{ display: "flex", flexDirection: "column" }}>
							<div className="panel-detail-label">Notes</div>
							<div className="panel-item-content">
								{setTaskPanelContent(taskInfo, "notes")}
							</div>
						</div>
						<div
							style={{
								display: "flex",
								flexWrap: "wrap",
								columnGap: "35px",
								rowGap: "20px",
							}}
						>
							{/* Responsible */}
							<div style={{ display: "flex", flexDirection: "column" }}>
								<div className="panel-detail-label">Responsible</div>
								<div className="panel-item-content">
									{setTaskPanelContent(taskInfo, "responsible_roles")}
								</div>
							</div>

							{/* Accountable */}
							<div style={{ display: "flex", flexDirection: "column" }}>
								<div className="panel-detail-label">Accountable</div>
								<div className="panel-item-content">
									{setTaskPanelContent(taskInfo, "accountable_roles")}
								</div>
							</div>

							{/* Consulted */}
							<div style={{ display: "flex", flexDirection: "column" }}>
								<div className="panel-detail-label">Consulted</div>
								<div className="panel-item-content">
									{setTaskPanelContent(taskInfo, "consulted")}
								</div>
							</div>

							{/* Informed */}
							<div style={{ display: "flex", flexDirection: "column" }}>
								<div className="panel-detail-label">Informed</div>
								<div className="panel-item-content">
									{setTaskPanelContent(taskInfo, "informed_parties")}
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	};

	// Tab Buttons
	const buttonTopic = (item: any, iconType: string) => {
		return (
			<div
				className={activePage === item.key ? "project-active-button" : ""}
				style={{
					display: "flex",
					padding: "4px 15px",
					gap: "8px",
					cursor: "pointer",
				}}
				onClick={() => {
					setActivePage(item.key);
				}}
			>
				{/* Pass the `iconType` to get different icons based on different tab buttons */}
				{GetAntIcon(iconType)}
				<span>{item.label}</span>
			</div>
		);
	};

	const setRoles = (baseData: any, key: any) => {
		let content: any = setLookupData(context.allData, baseData, "roles", key);
		if (content?.length > 0) {
			return content.map((roles: any) => {
				return (
					<Tag className="deliverable-tag" key={roles._id}>
						{roles?.name}
					</Tag>
				);
			});
		} else {
			return <span>-</span>;
		}
	};

	const updateDeliverableAndAlertMessage = (
		deliverable_id: any,
		newData: any,
		initialData: any
	) => {
		updateDeliverableItem(
			context.currentTenantKey,
			deliverable_id,
			{ ...newData, project_code, operationCode, task_id },
			initialData,
			props.params.user
		)
			.then((updatedData: any) => {
				const updatedDeliverableList = deliverableList?.map((item: any) =>
					item._id === updatedData._id ? updatedData : item
				);
				setDeliverableList(updatedDeliverableList);
				setSideContainerData(updatedData);

				const taskData: any = taskInfo;
				taskData.project_code = project?.project_code;
				taskData.operation_code = context?.currentOperation?.operation_code;
				const allDeliverablesStatus = updatedDeliverableList.map(
					(item: any) => item.status
				);

				if (
					allDeliverablesStatus.every((status: any) => status === "Resolved")
				) {
					taskData.status = "Resolved";
				} else if (
					allDeliverablesStatus.every((status: any) => status === "New")
				) {
					taskData.status = "New";
				} else {
					taskData.status = "In progress";
				}

				// updateTaskItem(
				// 	context.currentTenantKey,
				// 	taskInfo._id,
				// 	{
				// 		...taskData,
				// 		project_code: project_code,
				// 		operation_code: operation_code,
				// 	},
				// 	null,
				// 	props.params.user
				// ).then((updatedTaskData: any) => {
				// 	setTaskInfo(updatedTaskData);
				// 	socket.emit("UPDATING_DATA");
				// });

				let alertMessage: any = null;
				switch (newData?.status) {
					case "In progress":
						alertMessage = "Deliverable status updated to 'In progress'.";
						break;
					case "In review":
						alertMessage = "Deliverable submitted for review.";
						break;
					case "Resolved":
						alertMessage = "Deliverable marked as 'Resolved'.";
						break;
				}

				if (alertMessage) {
					Emitter.emit("alert", {
						type: "success",
						message: alertMessage,
						description: "",
						top: true,
						closeable: false,
						timeout: 3000,
					});
				}

				form.resetFields();
			})
			.catch((error: any) => {});
	};

	const handleSearch = (record: any) => {
		setTempRecord(record);
	};

	const renderDeliverables = () => {
		const deliverablesColumn: any = [
			{
				title: (
					<SearchButton
						handleSearch={handleSearch}
						headerName="Deliverables"
						columnKey="deliverable_name"
						key="deliverables"
					/>
				),
				dataIndex: "deliverable_name",
				key: "deliverable_name",
				width: 400,
				render: (text: any, record: any) => {
					const tooltipText = record?.disabled &&
						record?.disabled_reason != "" && (
							<span>
								This deliverable is excluded from the well programme. You may
								still click to view the details. <br />
								<br /> <b>Comment:</b>
								<br />
								{record?.disabled_reason}
							</span>
						);
					return (
						<Tooltip title={tooltipText}>
							<div
								style={{
									cursor: "pointer",
									display: "flex",
									gap: "10px",
									filter: record.disabled ? "grayscale(1)" : "none",
								}}
								onClick={() => {
									// Reset the state by navigating to the same path without state
									navigate(location.pathname, {
										replace: true,
										state: {
											deliverable_id: record._id,
											task_id: task_id,
										},
									});

									setSideContainerOpen(true);
									setDeliverableKey(record._id);
									setSideContainerData(record);
								}}
							>
								{record.disabled && excludedMark()}
								{statusIcon(record.status)}
								<span className="table-clickable-text">{text}</span>
							</div>
						</Tooltip>
					);
				},
			},
			{
				title: (
					<SearchButton
						handleSearch={handleSearch}
						headerName="Assigned to"
						columnKey="assigned_to_names"
						key="assigned_to_names"
					/>
				),
				dataIndex: "assigned_to_names",
				key: "assigned_to_names",
				width: 300,
				render: (_: any, record: any) => {
					let content: any = setLookupData(
						context.allData,
						record,
						"roles",
						"assigned_to"
					);
					if (content?.length > 0) {
						return content.map((roles: any) => {
							return (
								<Tag className="deliverable-tag" key={roles._id}>
									{roles?.name}
								</Tag>
							);
						});
					}
				},
			},
			{
				title: (
					<SearchButton
						handleSearch={handleSearch}
						headerName="Reviewed by"
						columnKey="reviewed_by_names"
						key="reviewed_by_names"
					/>
				),
				dataIndex: "reviewed_by_names",
				key: "reviewed_by_names",
				width: 300,
				render: (_: any, record: any) => {
					let content: any = setLookupData(
						context.allData,
						record,
						"roles",
						"reviewed_by"
					);
					if (content?.length > 0) {
						return content.map((roles: any) => {
							return (
								<Tag className="deliverable-tag" key={roles._id}>
									{roles?.name}
								</Tag>
							);
						});
					}
				},
			},
		];

		const handleFileDownload = (file: any) => {
			let azureFilePath = `${context.currentProject.project_code}/${context.currentOperation.operation_code}/${context.currentPhase._id}/${context.currentTask._id}/${deliverableKey}/${file.actual_name}`;
			let tenant = context.currentTenantKey;
			fileDownload(file, azureFilePath, tenant, true)
				.then(() => {
					Emitter.emit("alert", {
						type: "success",
						message: "File succesfully downloaded.",
						description: "",
						top: true,
						closeable: false,
						timeout: 3000,
					});
				})
				.catch((error: any) => {
					Emitter.emit("alert", {
						type: "error",
						message: "File not found on server. Please contact administrator.",
						description: "",
						top: true,
						closeable: false,
						timeout: 3000,
					});
				});
		};

		/* Deliverables Side Container */
		const sideContainer = () => {
			return (
				<div className="deliverable-side-container">
					<div
						style={{ position: "relative", overflow: "auto", padding: "10px" }}
					>
						<Button
							type="link"
							className="deliverable-card-close-button"
							onClick={() => {
								navigate(location.pathname, {
									replace: true,
									state: null,
								});
								setSideContainerOpen(false);
							}}
						>
							{GetAntIcon("close")}
						</Button>
						<div>
							{sideContainerData && (
								<Form
									form={form}
									className={`deliverable-card-form ${
										sideContainerData.status === "Resolved"
											? "padding-resolved"
											: "padding-not-resolved"
									}`}
									onFinish={(values: any) => {
										updateDeliverableAndAlertMessage(
											sideContainerData._id,
											values,
											sideContainerData
										);
									}}
								>
									{sideContainerData.disabled &&
										sideContainerData.disabled_reason && (
											<span className="disabled-reason">
												**Excluded from well plan. Comment:{" "}
												{sideContainerData.disabled_reason}
											</span>
										)}
									<div className="deliverable-card-form-item">
										<span className="deliverable-card-form-label">
											Deliverable title
										</span>
										<span
											style={{
												fontSize: "14px",
												textAlign: "left",
												height: "min-content",
											}}
										>
											{sideContainerData?.deliverable_name}
										</span>
									</div>
									<div className="deliverable-card-form-item">
										<span className="deliverable-card-form-label">Status</span>
										<Form.Item
											name={"status"}
											style={{ padding: 0, margin: 0 }}
										>
											<span className="form-item-content">
												{sideContainerData?.status}
											</span>
										</Form.Item>
									</div>
									<div
										style={{
											display: "flex",
											gap: "20px",
											// flexGrow: 1,
											flexWrap: "wrap",
										}}
									>
										<div className="deliverable-card-form-item">
											<span className="deliverable-card-form-label">
												Assigned to
											</span>
											<span className="form-item-content">
												{setRoles(sideContainerData, "assigned_to")}
											</span>
										</div>
										<div className="deliverable-card-form-item">
											<span className="deliverable-card-form-label">
												Reviewed by
											</span>
											<span className="form-item-content">
												{setRoles(sideContainerData, "reviewed_by")}
											</span>
										</div>
									</div>
									<div className="deliverable-card-form-item">
										<span className="deliverable-card-form-label">
											Description
										</span>
										<span className="form-item-content">
											{sideContainerData?.description || "-"}
										</span>
									</div>
									<div className="deliverable-card-form-item">
										<span className="deliverable-card-form-label">
											Document links
										</span>
										{sideContainerData.document_links &&
										sideContainerData?.document_links?.length > 0 ? (
											sideContainerData?.document_links.map((docLink: any) => {
												return (
													<span
														className="text-clickable form-item-content"
														onClick={() => {
															let url = docLink.link;
															if (!/^https?:\/\//i.test(url)) {
																url = "https://" + url;
															}
															window.open(url, "_blank");
														}}
													>
														{docLink.title}
													</span>
												);
											})
										) : (
											<span>-</span>
										)}
									</div>
									<div className="deliverable-card-form-item">
										<span className="deliverable-card-form-label">
											Attachment(s)
										</span>
										<span className="form-item-content">
											<Form.Item
												name="attachments"
												valuePropName="formList.name"
												getValueFromEvent={(e: any) => {
													if (Array.isArray(e)) {
														return e;
													}
													return e?.fileList;
												}}
											>
												<Upload
													customRequest={async (info: any) => {
														let count = 1;
														let originalName = info.file.name;
														let filename = originalName;
														let temp_attachments = [
															...form.getFieldValue("attachments"),
														];
														temp_attachments.pop();
														// while (
														// 	temp_attachments?.some(
														// 		(file: any) => file.name === filename
														// 	)
														// ) {
														// 	const lastDotIndex =
														// 		originalName.lastIndexOf(".");
														// 	if (lastDotIndex !== -1) {
														// 		filename = `${originalName.substring(
														// 			0,
														// 			lastDotIndex
														// 		)}(${count})${originalName.substring(
														// 			lastDotIndex
														// 		)}`;
														// 	} else {
														// 		filename = `${originalName}`;
														// 	}
														// 	count++;
														// }
														const formData = new FormData();
														formData.append("attachments", info.file);
														formData.append(
															"filepath",
															`${context.currentProject.project_code}/${context.currentOperation.operation_code}/${context.currentPhase._id}/${context.currentTask._id}/${deliverableKey}/`
														);
														formData.append("tenant", context.currentTenantKey);
														await fileUpload(formData)
															.then((data: any) => {
																info.onSuccess(data, info.file);

																if (
																	sideContainerData?.status == "In progress"
																) {
																	Emitter.emit("alert", {
																		type: "success",
																		message: "Attachment succesfully uploaded.",
																		description: "",
																		top: true,
																		closeable: false,
																		timeout: 3000,
																	});
																}

																let attachments =
																	form.getFieldValue("attachments") || [];

																let foundAttachmentIndex =
																	attachments.findIndex(
																		(item: any) => item.name === info.file.name
																	);

																if (foundAttachmentIndex != -1) {
																	attachments[foundAttachmentIndex].name =
																		filename;
																	attachments[
																		foundAttachmentIndex
																	].actual_name = data.data[0].file_name;
																	attachments[foundAttachmentIndex].file_path =
																		data.data[0].file_path;
																	attachments[foundAttachmentIndex].uploadDate =
																		data.data[0].upload_date;
																	attachments[foundAttachmentIndex].status =
																		"done";
																}

																form.setFieldsValue({
																	attachments: attachments,
																});
																if (
																	sideContainerData?.status !== "In progress"
																) {
																	form.setFieldsValue({
																		status: "In progress",
																	});
																}

																// //Check if all attachments upload status === done
																const checkDone = attachments.every(
																	(attachment: any) =>
																		attachment.status === "done"
																);

																if (checkDone) {
																	form.submit();
																}
															})
															.catch((error: any) => {
																info.onError(error, info.file);
																Emitter.emit("alert", {
																	type: "error",
																	message: "File upload failed.",
																	description: "",
																	top: true,
																	closeable: false,
																	timeout: 3000,
																});
															})
															.finally(() => {});
													}}
													onRemove={(file: any) => {
														// Create a popconfirm to confirm file deletion
														let azureFilePath = `${context.currentProject.project_code}/${context.currentOperation.operation_code}/${context.currentPhase._id}/${context.currentTask._id}/${deliverableKey}/${file.actual_name}`;
														let tenant = context.currentTenantKey;
														fileRemove(file, azureFilePath, tenant)
															.then(() => {
																Emitter.emit("alert", {
																	type: "success",
																	message: "File succesfully removed.",
																	description: "",
																	top: true,
																	closeable: false,
																	timeout: 3000,
																});
																let attachments =
																	form.getFieldValue("attachments");
																let foundAttachmentIndex =
																	attachments.findIndex(
																		(item: any) => item.uid === file.uid
																	);
																if (foundAttachmentIndex != -1) {
																	attachments.splice(foundAttachmentIndex, 1);
																}

																form.setFieldsValue({
																	attachments: attachments,
																});
																form.submit();
															})
															.catch((error: any) => {});
													}}
													showUploadList={{
														showDownloadIcon: true,
														showRemoveIcon:
															sideContainerData?.status === "New" ||
															sideContainerData?.status === "In progress"
																? true
																: false,
													}}
													multiple={true}
													fileList={sideContainerData?.attachments.sort(
														(a: any, b: any) => {
															if (a.uploadDate && b.uploadDate) {
																return b.uploadDate.localeCompare(a.uploadDate);
															} else if (!a.uploadDate && !b.uploadDate) {
																return 0; // Both have no uploadDate, maintain order
															} else if (!a.uploadDate) {
																return 1; // a has no uploadDate, so it comes after b
															} else {
																return -1; // b has no uploadDate, so it comes before a
															}
														}
													)}
													onDownload={(file) => handleFileDownload(file)}
													beforeUpload={(fileList: any) => {
														form.setFieldsValue({ attachments: fileList });
													}}
													itemRender={(
														origin: any,
														file: any,
														fileList: any,
														actions: any
													) => {
														let index1 = fileList.findIndex(
															(_file: any) =>
																dayjs(_file?.uploadDate).format(
																	"DD MMM YYYY"
																) ===
																dayjs(file?.uploadDate).format("DD MMM YYYY")
														);
														let index2 = fileList.findIndex(
															(_file: any) => _file.uid === file.uid
														);
														const width =
															// document.getElementById("date")?.clientWidth;
															"100px";

														return (
															<div
																style={{
																	width: "100%",
																	display: "flex",
																	flexDirection: "row",
																	gap: "20px",
																	alignItems: "center",
																}}
															>
																<>
																	{index1 === index2 ? (
																		<div
																			id="date"
																			className="attachment-date"
																			style={{
																				fontStyle: file.uploadDate
																					? "normal"
																					: "italic",
																				width: width,
																			}}
																		>
																			{file.uploadDate
																				? dayjs(file?.uploadDate).format(
																						"DD MMM YYYY"
																				  )
																				: "No data"}
																		</div>
																	) : (
																		<div
																			style={{
																				width: width,
																			}}
																		>
																			{" "}
																		</div>
																	)}
																</>
																<Input
																	style={{ flexGrow: 1 }}
																	className="attachment-file-item"
																	readOnly
																	value={file.name}
																	onClick={() => {
																		let azureFilePath = `${context.currentProject.project_code}/${context.currentOperation.operation_code}/${context.currentPhase._id}/${context.currentTask._id}/${deliverableKey}/${file.actual_name}`;
																		let tenant = context.currentTenantKey;
																		fileDownload(
																			file,
																			azureFilePath,
																			tenant,
																			false
																		);
																	}}
																	addonAfter={
																		<div
																			style={{
																				width: "100%",
																				height: "100%",
																				padding: "0 11px",
																			}}
																			onClick={actions.download}
																		>
																			{GetAntIcon("download")}
																		</div>
																	}
																></Input>
																{sideContainerData?.status !== "New" &&
																sideContainerData?.status !== "In progress" ? (
																	<></>
																) : (
																	<Popconfirm
																		overlayInnerStyle={{
																			borderRadius: "0",
																			maxWidth: "300px",
																		}}
																		placement="topLeft"
																		arrow={{ pointAtCenter: true }}
																		title={
																			<div
																				style={{
																					padding: "10px",
																				}}
																			>
																				Remove this attachment? You can still
																				add it back later.
																			</div>
																		}
																		//description="Permanently delete this item? This action cannot be undone."
																		onConfirm={actions.remove}
																		overlayClassName="popconfirm-danger"
																		onCancel={() => {
																			// Handle cancel action if needed
																		}}
																		okText="Remove"
																		okType="danger"
																		icon={
																			<ExclamationCircleOutlined
																				style={{ color: "grey" }}
																			/>
																		}
																		cancelText={<span>Cancel</span>}
																		cancelButtonProps={{ ghost: true }}
																	>
																		<div
																			className="attachment-close"
																			style={{ cursor: "pointer" }}
																		>
																			{GetAntIcon("close")}
																		</div>
																	</Popconfirm>
																)}
															</div>
														);
													}}
													className="deliverables-attachment-file-container"
												>
													<>
														<Button
															type="default"
															style={{
																display: "hidden",
																marginBottom: "8px",
															}}
															disabled={
																taskInfo?.closed === true ||
																sideContainerData?.disabled ||
																(sideContainerData?.status !== "New" &&
																	sideContainerData?.status !== "In progress")
																	? true
																	: false
															}
														>
															{GetAntIcon("upload")} Upload
														</Button>
													</>
												</Upload>
											</Form.Item>
											<a
												style={{ marginTop: "auto" }}
												onClick={() => {
													setAuditDeliverableOpen(true);
												}}
											>
												{GetAntIcon("history")} Audit log
											</a>
										</span>
									</div>
								</Form>
							)}
						</div>
					</div>
					<div>
						{sideContainerData.status === "New" && taskInfo?.closed !== true ? (
							<Button
								className={
									!sideContainerData?.disabled
										? "deliverable-status-button mark-as-in-progress-button"
										: "deliverable-status-button deliverable-status-button-disabled"
								}
								onClick={() => {
									form.setFieldsValue({ status: "In progress" });
									form.submit();
								}}
								disabled={sideContainerData?.disabled}
								type="primary"
							>
								Mark as in progress
							</Button>
						) : sideContainerData.status === "In progress" &&
						  taskInfo?.closed !== true ? (
							<Popconfirm
								placement="topLeft"
								title={
									<div style={{ maxWidth: "300px", padding: "10px" }}>
										Submit for review? This will notify the responsible and
										accountable users for review and no further updates are
										allowed.
									</div>
								}
								overlayClassName="popconfirm-danger"
								icon={<ExclamationCircleOutlined style={{ color: "grey" }} />}
								onConfirm={() => {
									form.setFieldsValue({ status: "In review" });
									form.submit();
								}}
							>
								<Button
									className={
										!sideContainerData?.disabled
											? "deliverable-status-button submit-for-review-button"
											: "deliverable-status-button deliverable-status-button-disabled"
									}
									disabled={sideContainerData?.disabled}
									type="primary"
								>
									Submit for review
								</Button>
							</Popconfirm>
						) : sideContainerData.status === "In review" &&
						  taskInfo?.closed !== true ? (
							checkPrivilege(props.params.userRole, ["project-manager"]) ? (
								<Button
									disabled={sideContainerData?.disabled}
									className={
										!sideContainerData?.disabled
											? "deliverable-status-button mark-as-complete-button"
											: "deliverable-status-button deliverable-status-button-disabled"
									}
									onClick={() => {
										form.setFieldsValue({ status: "Resolved" });
										form.submit();
									}}
									type="primary"
								>
									Mark as complete
								</Button>
							) : (
								<Button
									className="deliverable-status-button submitted-for-review-button"
									type="primary"
									disabled
								>
									Submitted for review
								</Button>
							)
						) : (
							<></>
						)}
					</div>
				</div>
			);
		};

		return (
			<div
				style={{
					flex: "1",
					display: "flex",
					flexDirection: "row",
					gap: "20px",
					maxHeight: "100%",
					minHeight: "100%",
					overflow: "hidden",
				}}
			>
				<div
					style={{
						width: "60%",
						borderRadius: "3px",
						overflow: "hidden",
					}}
					ref={tableRef}
				>
					<Table
						rootClassName="deliverables-table"
						columns={deliverablesColumn}
						dataSource={filteredDeliverableList || deliverableList}
						pagination={false}
						bordered
						scroll={{
							// y: tableDimensions.height - 65 - 64,
							y: 400,
							x: tableDimensions.width,
						}}
					/>
				</div>
				{sideContainerOpen && sideContainer()}
			</div>
		);
	};

	const handleCloseTask = (task: any) => {
		getCrudaClass("task").then((cruda: any) => {
			const initialData = { ...task };
			task.closed = true;
			task.reopen_reason = "";
			cruda
				.updateLookupItem(
					"tasks",
					null,
					task._id,
					context?.currentTenantKey,
					task,
					initialData,
					null,
					props.params.user,
					[],
					{
						level: "task",
						level_id: task._id,
						event_type: "update",
						event: `Closed the task`,
						details: [],
						ref_tags: {
							operation_code: task.operation_code,
							phase_id: task.phase_id,
						},
						created_on: new Date(),
						created_by: props.params.user.email,
					}
				)
				.then(() => {
					loadData(
						project_code,
						operationCode,
						phase_id,
						task_id,
						deliverableKey,
						context.allData
					);
					Emitter.emit("alert", {
						type: "success",
						message: `Task has been successfully closed.`,
						description: "",
						top: true,
						closeable: false,
						timeout: 3000,
					});
				})
				.catch(() => {
					Emitter.emit("alert", {
						type: "error",
						message: `Fail to close task.`,
						description: "",
						top: true,
						closeable: false,
						timeout: 3000,
					});
				});
		});
	};

	const handleReopenTask = (task: any) => {
		getCrudaClass("task").then((cruda: any) => {
			const initialData = { ...task };
			task.closed = false;
			cruda
				.updateLookupItem(
					"tasks",
					null,
					task._id,
					context?.currentTenantKey,
					task,
					initialData,
					null,
					props.params.user,
					[],
					{
						level: "task",
						level_id: task._id,
						event_type: "update",
						event: `Reopened the task`,
						details: [
							{
								new_data: "Comment: Update on well plans",
							},
						],
						ref_tags: {
							operation_code: task.operation_code,
							phase_id: task.phase_id,
						},
						created_on: new Date(),
						created_by: props.params.user.email,
					}
				)
				.then(() => {
					loadData(
						project_code,
						operationCode,
						phase_id,
						task_id,
						deliverableKey,
						context.allData
					);
					Emitter.emit("alert", {
						type: "success",
						message: `Task is successfully reopened.`,
						description: "",
						top: true,
						closeable: false,
						timeout: 3000,
					});
				})
				.catch(() => {
					Emitter.emit("alert", {
						type: "error",
						message: `Fail to open task.`,
						description: "",
						top: true,
						closeable: false,
						timeout: 3000,
					});
				});
			setReopenModal(false);
		});
	};

	const resetForm = (values: any = null, reset: any = true) => {
		if (reopenForm) {
			setFormReset(true);
			reset && reopenForm.resetFields();
			reopenForm.setFieldsValue(values ? { ...values } : null);
			// setInitialForm(values);
		}
	};

	const handleFormCancel = () => {
		setReopenModal(false);
		setCancelPopover(false);
		// handleModalClose();
		resetForm();
	};

	const handleFormSave = async (values: any) => {
		// update values information
		values._id = taskInfo._id;
		values.closed = true;

		handleReopenTask(values);
		setReopenModal(false);
		resetForm();
		context.handleFormUpdating(false);
	};

	return (
		<>
			{taskInfo && (
				<div className="task-overview generic-container">
					{/* Top Container */}
					<div className="top-container main-container">
						{/* Top Container Header */}
						<div className="container-header">
							<div
								className="panel-info"
								onClick={() => {
									setTaskDetailsExpand(!taskDetailsExpand);
								}}
							>
								<span>Task details</span>
								{/* Dropdown Button Icon */}
								{taskDetailsExpand ? GetAntIcon("up") : GetAntIcon("downarrow")}
							</div>
						</div>
						{/* (Expanded/Contracted) Task details */}
						{taskDetailsExpand ? (
							<div className="details-content project-details-container">
								{renderMetaData()}
								<div className="task-details-content">
									{/* Hide the button for 'Closed' status and unrelated roles */}
									{checkProjectTeam(
										props.params.user.email,
										responsibleParty
									) && (
										<>
											{taskInfo?.disabled !== true && (
												<>
													{taskInfo?.closed !== true ? (
														<Popconfirm
															icon={
																<ExclamationCircleOutlined
																	style={{ color: "grey" }}
																/>
															}
															title={
																<div
																	style={{
																		maxWidth: "300px",
																		padding: "10px",
																	}}
																>
																	<>
																		Closing this task will update its status to
																		'Closed.' You may still reopen the task if
																		needed. Do you wish to proceed?
																	</>
																</div>
															}
															placement="topRight"
															cancelButtonProps={{ ghost: true }}
															onConfirm={() => handleCloseTask(taskInfo)}
															okText="Close task"
															okType="default"
														>
															<Button type="primary">
																{GetAntIcon("checkcircle")} Close task
															</Button>
														</Popconfirm>
													) : (
														<Button
															className="ant-btn-secondary"
															onClick={() => setReopenModal(true)}
														>
															{GetAntIcon("checkcircle")} Reopen task
														</Button>
													)}
													<Divider
														style={{ color: "#FFFFFF1A" }}
														type="vertical"
													/>
												</>
											)}
										</>
									)}

									<a
										onClick={() => {
											setAuditOpen(true);
										}}
									>
										{GetAntIcon("history")} Audit log
									</a>
								</div>
							</div>
						) : null}
						{reopenModal && (
							<Modal
								closable={false}
								width={"40%"}
								title="Reopen task?"
								open={reopenModal}
								centered
								destroyOnClose
								footer={() => {
									return (
										<div
											className="generic-footer"
											style={{ height: "auto", padding: "0", width: "100%" }}
										>
											<Popconfirm
												icon={
													<ExclamationCircleOutlined
														style={{ color: "grey" }}
													/>
												}
												title={
													<div style={{ maxWidth: "300px", padding: "10px" }}>
														<>
															You have unsaved changes. Discard them or continue
															editing?
														</>
													</div>
												}
												open={cancelPopover}
												placement="topRight"
												cancelButtonProps={{ ghost: true }}
												cancelText="Discard changes"
												onCancel={() => {
													setCancelPopover(false);
													// handleFormCancel();
													setTimeout(() => {
														handleFormCancel();
													}, 300);
												}}
												okText="Continue editing"
												okType="default"
												onConfirm={() => {
													setCancelPopover(false);
												}}
											>
												<Button
													className="ant-btn-secondary"
													onClick={() => {
														if (formReset) {
															handleFormCancel();
														} else {
															setCancelPopover(true);
														}
													}}
												>
													Cancel
												</Button>
											</Popconfirm>
											<Popconfirm
												icon={
													<ExclamationCircleOutlined
														style={{ color: "grey" }}
													/>
												}
												title={
													<div style={{ maxWidth: "300px", padding: "10px" }}>
														<>
															You have unsaved changes. Discard them or continue
															editing?
														</>
													</div>
												}
												placement="topRight"
											>
												<FormButtonSave
													form={reopenForm}
													handleFormSave={handleFormSave}
													formReset={formReset}
													setFormReset={setFormReset}
												></FormButtonSave>
											</Popconfirm>
										</div>
									);
								}}
							>
								<CustomForm
									setFormReset={setFormReset}
									formRef={reopenForm}
									tabKey={"task-reopen"}
								/>
							</Modal>
						)}
					</div>
					{/* Bottom Container */}
					<div className="bottom-container main-container">
						{/* Bottom Container Header */}
						<div className="project-header">
							{/* Tab Buttons */}
							<div
								style={{ display: "flex", flexDirection: "row", gap: "10px" }}
							>
								{buttonTopic(
									{ key: "deliverables", label: "Deliverables" },
									"file2"
								)}
								{buttonTopic(
									{ key: "discussion", label: "Discussion" },
									"comment"
								)}
							</div>
						</div>
						<div className="generic-content project-overview-container">
							{/* Deliverables */}
							{activePage === "deliverables" && (
								<div className="kanban-columns-container">
									{deliverableList?.length > 0 ? (
										renderDeliverables()
									) : (
										<Empty
											className="no-data-empty"
											style={{ margin: "auto", fontSize: "20px" }}
											image={elementalSvg}
											description="Deliverables are not added yet."
										/>
									)}
								</div>
							)}
							{/* Discussion */}
							{activePage === "discussion" && (
								<div
									className="kanban-columns-container"
									style={{
										display: "flex",
										flexDirection: "column",
									}}
								>
									{/* Add Comment Container */}
									<div
										className="add-comment-container"
										ref={containerRef} // Attach ref to the parent container
										style={{
											width: "100%",
											height: "auto",
											borderRadius: "5px",
											padding: "20px 30px 25px 30px",
											backgroundColor: "rgba(255, 255, 255, 0.1)",
											boxShadow: "7px 7px 11px 0px #00000040",
										}}
									>
										<div
											style={{
												display: "flex",
												flexDirection: "column",
												gap: "15px",
												fontSize: "14px",
											}}
										>
											<div
												style={{
													display: "flex",
													gap: "11px",
												}}
											>
												{/* Avatar and Name */}
												<Avatar
													size={20}
													icon={GetAntIcon("user")}
													src={props.params.user.profile_picture}
												/>
												<Link
													to={`/profile`} // Navigate to the user's profile page based on their ID
												>
													<span>{props.params.user.name}</span>
												</Link>
											</div>
											<div>
												{/* Text Editor */}
												<TextEditor
													ref={editorRef}
													onChange={setNewComment}
													onClick={() => setComponentsHide(false)}
													value={newComment}
												/>
											</div>
											{/* Buttons Container */}
											{!componentsHide && (
												<div>
													<div
														style={{
															display: "flex",
															justifyContent: "end",
															gap: "8px",
														}}
													>
														<Button onClick={() => setComponentsHide(true)}>
															Cancel
														</Button>
														<Button
															style={{}}
															onClick={handleAddComment}
															disabled={addButtonDisabled}
														>
															Save
														</Button>
													</div>
												</div>
											)}
										</div>
									</div>
									{/* Comment Container */}
									{comments
										.slice()
										.reverse()
										.map((comment) => {
											// Check if comment is defined and has a comment property
											if (!comment || !comment.comment) {
												return null; // or return a fallback UI element
											}
											return (
												<div
													key={comment._id}
													style={{
														width: "100%",
														height: "auto",
														borderRadius: "5px",
														padding: "20px 30px 25px 30px",
														boxShadow: "7px 7px 11px 0px #00000040",
														marginBottom: "15px",
														position: "relative",
													}}
													className="comment-container"
												>
													<div
														style={{
															display: "flex",
															flexDirection: "column",
															gap: "15px",
															fontSize: "14px",
														}}
													>
														<div
															style={{
																display: "flex",
																justifyContent: "space-between",
															}}
														>
															<div
																style={{
																	display: "flex",
																	gap: "11px",
																}}
															>
																{/* User Avatar */}
																<Avatar
																	size={20}
																	icon={GetAntIcon("user")}
																	// returns only the profile picture of the commented user
																	src={
																		commentingUser(comment.user)
																			?.profile_picture
																	}
																/>
																{/* Username */}
																{props?.params?.user?.email === comment.user ? (
																	<Link to={`/profile`}>
																		<span>{comment.user}</span>
																	</Link>
																) : (
																	<span>{comment.user}</span>
																)}
																{/* Comment DateTime */}
																<span className="comment-date-time">
																	{new Date(comment.created_on)
																		.toLocaleString("en-GB", {
																			day: "numeric",
																			month: "short",
																			year: "numeric",
																			hour: "2-digit",
																			minute: "2-digit",
																			second: "2-digit",
																			hour12: true,
																		})
																		.replace(",", "")
																		.replace(" am", " AM")
																		.replace(" pm", " PM")}
																</span>
															</div>
															{/* */}
															{props?.params?.user?.email === comment.user ? (
																<div>
																	<div
																		style={{
																			display: "flex",
																			gap: "11px",
																		}}
																	>
																		{/* Edit Button */}
																		<Button
																			size="small"
																			shape="circle"
																			icon={GetAntIcon("edit")}
																			style={{ border: "none" }}
																			onClick={() =>
																				handleEditInit(
																					comment._id,
																					comment.comment
																				)
																			}
																		/>
																		{/* Delete Button */}
																		<Button
																			size="small"
																			shape="circle"
																			icon={GetAntIcon("delete")}
																			style={{ border: "none" }}
																			onClick={() =>
																				handleDeleteInit(comment._id)
																			}
																		/>
																		{/* Delete Confirmation Process */}
																		{deleteCommentClick &&
																			comment._id === deleteCommentID && (
																				<div
																					style={{
																						marginTop: "20px",
																						position: "absolute",
																						right: "20px",
																					}}
																				>
																					<Popconfirm
																						placement="bottomLeft"
																						title={
																							<div
																								style={{
																									maxWidth: "300px",
																									padding: "10px",
																								}}
																							>
																								<>
																									Permanently delete this
																									comment?
																									<br />
																									This action cannot be undone.
																								</>
																							</div>
																						}
																						open={deleteCommentClick}
																						onConfirm={() =>
																							handleDeleteComment(comment._id)
																						}
																						overlayClassName="popconfirm-danger"
																						onCancel={() => {
																							setDeleteCommentClick(false);
																						}}
																						okText="Delete"
																						okType="danger"
																						icon={
																							<ExclamationCircleOutlined
																								style={{ color: "grey" }}
																							/>
																						}
																						cancelText={<span>Cancel</span>}
																						cancelButtonProps={{ ghost: true }}
																					>
																						<Tooltip
																						/*title={
																						taskRecord?.closed === true
																							? "This deliverable is not allowed to be deleted as it is closed."
																							: ""
																					}*/
																						>
																							{/*
																					<Button
																							className="ant-btn-secondary"
																							style={{ marginRight: "auto" }}
																							danger
																							onClick={() => {
																								setDeleteCommentClick(true);
																							}}
																						>
																							Delete
																						</Button>
																					*/}
																						</Tooltip>
																					</Popconfirm>
																				</div>
																			)}
																	</div>
																</div>
															) : (
																<></>
															)}
														</div>
														{/* Edit Comment Process */}
														{editCommentClick &&
														editCommentID === comment._id ? (
															<div
																style={{
																	display: "flex",
																	flexDirection: "column",
																	gap: "15px",
																}}
															>
																<TextEditor
																	ref={editorRef}
																	value={editCommentContent}
																	onChange={setEditCommentContent}
																/>
																<div
																	style={{
																		display: "flex",
																		justifyContent: "end",
																		gap: "8px",
																	}}
																>
																	<Button
																		onClick={() =>
																			handleEditInit(
																				comment._id,
																				comment.comment
																			)
																		}
																	>
																		Cancel
																	</Button>
																	<Button
																		disabled={!editButtonDisabled} // Disable button if content hasn't changed
																		onClick={() =>
																			handleEditComment(
																				editCommentID,
																				editCommentContent,
																				comment.user
																			)
																		}
																	>
																		Save
																	</Button>
																</div>
															</div>
														) : (
															<div>
																{/* Comment Container */}
																<div className="comment-box">
																	{/* Splitting Lines: The split(/(<p[^>]*>.*?<\/p>)/g) regex separates each paragraph (<p> tag) */}
																	{comment.comment
																		.split(/(<p[^>]*>.*?<\/p>)/g)
																		// `.map` is used to iterate over any array
																		.map(
																			// line :any - To hold the element being processed (text or HTML elements)
																			// index :any - To represent the current index of the line within the array being processed
																			(line: any, index: any) => {
																				// Each line is checked for an indent level, which could either be from 0 to 8
																				const indentMatch =
																					line.match(/ql-indent-(\d+)/);
																				// If there is a classname `ql-indent-{}` appended to the line
																				const paddingLeft = indentMatch
																					? // If the string has ql-indent-2, indentMatch will capture 2
																					  `${
																							parseInt(indentMatch[1]) *
																							// Multiplies it by 39 pixels
																							39
																					  }px`
																					: // Defaults to 0 if there is no `ql-indent-{}` class
																					  "0";

																				return (
																					<div
																						key={index}
																						style={{ paddingLeft }}
																						dangerouslySetInnerHTML={{
																							__html: line,
																						}}
																					/>
																				);
																			}
																		)}
																</div>
															</div>
														)}
													</div>
												</div>
											);
										})}
								</div>
							)}
						</div>
					</div>
					<AuditLogModal
						tenantKey={context?.currentTenantKey}
						identifierKeys={{
							project_code: project_code,
							operation_code: operationCode,
							phase_id: phase_id,
							task_id: task_id,
						}}
						type={"task"}
						label={"Task"}
						open={auditOpen}
						handleClose={() => {
							setAuditOpen(false);
						}}
					/>

					<AuditLogModal
						tenantKey={context?.currentTenantKey}
						identifierKeys={{
							project_code: project_code,
							operation_code: operationCode,
							phase_id: phase_id,
							task_id: task_id,
							deliverable_id: sideContainerData?._id,
						}}
						type={"deliverable"}
						label={"Deliverable"}
						open={auditDeliverableOpen}
						handleClose={() => {
							setAuditDeliverableOpen(false);
						}}
					/>
				</div>
			)}
		</>
	);
}

export default TaskOverview;
