import React, { useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
	Button,
	Form,
	Input,
	InputNumber,
	Modal,
	Popconfirm,
	Popover,
	Select,
	Space,
	Tabs,
} from "antd";
import Emitter from "../utils/emitter";
import { useForm } from "antd/es/form/Form";
import "../assets/scss/projects.scss";
import FormButtonSave from "../components/FormButtonSave";
import FormButtonCancel from "../components/FormButtonCancel";
import { useLocation, useNavigate } from "react-router";
import { GetAntIcon } from "../utils/ant_icons";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { HomeContext } from "./Home";
import CRUDTabTableModal from "../components/CRUDTabTableModal";
import TaskConfigurationModal from "../components/Modal/TaskConfigurationModal";
import DeliverableConfigurationModal from "../components/Modal/DeliverableConfigurationModal";
import CustomForm from "../components/CustomForm";
import _ from "lodash";
import {
	deleteWellProgrammeTemplate,
	getWellProgrammeTemplateList,
	updateWellProgrammeTemplate,
} from "../services/api-server/well_programme_template";
import { getCrudaClass } from "../utils/lookup_list";
import AuditLogModal from "../components/Modal/AuditLogModal";

/*
	- currentWellProject is the project code of the current project
*/
function WellProgrammeTemplate(props: any) {
	const location = useLocation();
	const navigate = useNavigate();
	const context: any = useContext(HomeContext);
	const [isLoading, setIsLoading] = useState<any>(true);

	const [templateList, setTemplateList] = useState<any>([]);
	const [currentWellProgramme, setCurrentWellProgramme] = useState<any>(null);
	const [currentWellProgrammeData, setCurrentWellProgrammeData] =
		useState<any>(null);
	const [phaseTaskRecord, setPhaseTaskRecord] = useState<any>(null);
	const [deliverableRecord, setDeliverableRecord] = useState<any>(null);
	const [taskModalOpen, setTaskModalOpen] = useState<any>(false);
	const [deliverableModalOpen, setDeliverableModalOpen] = useState<any>(false);

	const [formRef]: any = useForm();
	const [formReset, setFormReset] = useState<boolean>(true);
	const [emptyModalOpen, setEmptyModalOpen] = useState<any>(null);

	const [initialForm, setInitialForm] = useState<any>(null);
	const [popOverVisibility, setPopOverVisibility] = useState<boolean>(false);
	const [dropdownOpen, setDropdownOpen] = useState<any>(false);

	const [allCampaignTasks, setAllCampaignTasks] = useState<any>(null);

	const [auditLogModalOpen, setAuditLogModalOpen] = useState<any>(false);
	const [auditLogProps, setAuditLogProps] = useState<any>(null);

	const tabList: any = [
		{ label: "Programme details", key: "well_programme_template" },
		{
			label: "Workflow configuration",
			key: "well-programme",
		},
	];
	const [tabKey, setTabKey] = useState<any>(() => {
		if (sessionStorage.getItem("main-tabkey")) {
			let sessionTabKey: any = sessionStorage.getItem("main-tabkey");
			let found = tabList.find((tab: any) => {
				return tab.key === sessionTabKey;
			});
			if (found) {
				return sessionTabKey;
			} else {
				sessionStorage.setItem("main-tabkey", "well_programme_template");
				return "well_programme_template";
			}
		} else {
			sessionStorage.setItem("main-tabkey", "well_programme_template");
			return "well_programme_template";
		}
	});

	useEffect(() => {
		if (context.currentTenantKey) {
			getWellProgrammeTemplateList(context.currentTenantKey)
				.then((templateList: any) => {
					setTemplateList(templateList);
					if (location.state) {
						setCurrentWellProgramme(location.state._id);
						let foundTemplate = templateList.find(
							(element: any) => element._id == location.state._id
						);
						setCurrentWellProgrammeData(foundTemplate);
						resetForm(foundTemplate);
					} else {
						setCurrentWellProgramme(templateList[0]._id);
						setCurrentWellProgrammeData(templateList[0]);
						resetForm(templateList[0]);
					}
				})
				.catch((e: any) => {})
				.finally(() => {
					setIsLoading(false);
				});
		}
	}, [context.currentTenantKey]);

	useEffect(() => {
		if (currentWellProgrammeData) {
			let data = currentWellProgrammeData.phases
				.map((element: any) => [...element.tasks])
				.flat(Infinity);

			const taskData = data;

			// sorting the task data ascending
			if (taskData?.length > 0) {
				taskData.sort((a: any, b: any) => {
					return parseFloat(a.task_number) - parseFloat(b.task_number);
				});
			}

			setAllCampaignTasks(taskData);

			if (phaseTaskRecord) {
				const currentPhaseRecord = currentWellProgrammeData.phases.find(
					(element: any) => element._id === phaseTaskRecord._id
				);
				let task = currentPhaseRecord.tasks.find(
					(element: any) => element._id === phaseTaskRecord?.task?._id
				);

				setPhaseTaskRecord({
					...currentPhaseRecord,
					task: task,
				});
			}
		}
	}, [currentWellProgrammeData]);

	// Emitter addition
	useEffect(() => {
		Emitter.on("taskModalOpen", (payload: any) => {
			setPhaseTaskRecord(payload);
			setTaskModalOpen(true);
		});

		Emitter.on("updateTemplate", (value: any) => {
			setCurrentWellProgrammeData(value);
		});
	}, []);

	// set the audit log filter for the current tab
	useEffect(() => {
		const getAuditLogProperties = (key: string) => {
			if (key) {
				switch (key) {
					case "well_programme_template":
						return {
							identifierKeys: {},
							auditlogFilter: [
								{
									level: key,
									level_id: currentWellProgramme,
									event_type: { $in: ["add", "update"] },
								},
							],
							label: "Well programme template",
							type: tabKey,
						};
				}
			}
		};
		const _auditLogsProps = getAuditLogProperties(tabKey);
		setAuditLogProps(_auditLogsProps);
	}, [tabKey, currentWellProgramme]);

	const handleTemplateChange = async (
		key: any = null,
		data: any = null,
		manual: boolean = false
	) => {
		try {
			await context.handlePageChange();
			// Emitter.emit("loading", true);
			setCurrentWellProgramme(key);

			//Always set tabKey to project-details when project has changed
			if (manual) {
				setTabKey("well_programme_template");
				sessionStorage.removeItem("tabKey");
			}

			sessionStorage.removeItem("tabKey");
			const templateRecord = templateList.find((template: any) => {
				// Emit the project name to set the breadcrumb
				return template._id == key;
			});
			setCurrentWellProgrammeData(templateRecord);
			resetForm(templateRecord, data);
			navigate(`/administration/programme-settings`, {
				state: templateRecord,
			});
		} catch {}
	};

	// Handling Task Modal close
	const handleTaskModalClose = () => {
		setPhaseTaskRecord(null);
	};

	// Force table re-render
	// Have to figure out a way to update just one specific task to the table renderer
	const handleTaskFormUpdate = (payload: any) => {
		if (payload) {
			setPhaseTaskRecord({ ...phaseTaskRecord, task: payload });
		}
	};

	const handleDeliverableModalClose = () => {
		setDeliverableModalOpen(false);
		setDeliverableRecord(null);
	};

	const handleDeliverableFormUpdate = (payload: any = null) => {
		// setPhaseTaskRecord({
		// 	...phaseTaskRecord,
		// 	task: { ...phaseTaskRecord.task },
		// });
		setDeliverableRecord(payload);
	};

	// reset form
	const resetForm = (values: any = null, data: any = null) => {
		if (formRef) {
			setFormReset(true);
			formRef.resetFields();
			formRef.setFieldsValue(values);

			setInitialForm(values);
		}
	};

	// Function to sort array of objects alphabetically based on property's name
	const sortData = (data: any) => {
		data.sort((a: any, b: any) => {
			if (a.label.toLowerCase() < b.label.toLowerCase()) {
				return -1;
			}
			if (a.label.toLowerCase() > b.label.toLowerCase()) {
				return 1;
			}
			return 0;
		});
		return data;
	};

	const handleTabChange = async (key: any) => {
		try {
			await context.handlePageChange();
			setTabKey(key);
			setFormReset(true);
			sessionStorage.setItem("main-tabkey", key);
			resetForm(currentWellProgrammeData, null);
			// setSideContainerOpen(false);
		} catch {}
	};

	// Handle form save
	const handleFormSave = (formValues: any | null = null, type: any = null) => {
		const _tabKey = tabKey || "well_programme_template";
		getCrudaClass(_tabKey).then((cruda: any) => {
			let values = formValues || formRef.getFieldsValue();

			// For each values in the form, trim the leading and trailing spaces
			Object.keys(values).forEach((key) => {
				if (typeof values[key] == "string") {
					values[key] = values[key].trim();
				}
			});

			Emitter.emit("loading", true);

			const form = cruda.getFormList(_tabKey, "general");
			const formList: any = [];
			form.forEach((element: any) => {
				if (element.children && !("name" in element)) {
					element.children.forEach((child_element: any) => {
						if (child_element.name) formList.push(child_element.name);
					});
				} else {
					if (element.name) formList.push(element.name);
				}
			});

			updateWellProgrammeTemplate(
				context?.currentTenantKey,
				currentWellProgramme,
				values,
				initialForm,
				props.params?.user,
				formList
			)
				.then((updatedData: any) => {
					Emitter.emit("alert", {
						type: "success",
						message: "Programme details has been successfully updated.",
						description: "",
						top: true,
						closeable: false,
						timeout: 3000,
					});

					getWellProgrammeTemplateList(context?.currentTenantKey)
						.then((newTemplateList: any) => {
							setTemplateList(newTemplateList);
							resetForm(values);
						})
						.catch((e: any) => {});
				})
				.catch((error: any) => {
					Emitter.emit("alert", {
						type: "error",
						message: "Failed to update. Please try again.",
						description: "",
						top: true,
						closeable: false,
						timeout: 3000,
					});
					resetForm(currentWellProgrammeData);
				})
				.finally(() => {
					Emitter.emit("loading", false);
				});
		});
	};

	// Handle form delete
	const handleFormDelete = (id: any) => {
		Emitter.emit("loading", true);
		context?.handleFormUpdating(false);
		deleteWellProgrammeTemplate(
			context?.currentTenantKey,
			id,
			props.params?.user
		)
			.then((data: any) => {
				Emitter.emit("alert", {
					type: "success",
					message: "Programme details has been successfully deleted.",
					description: "",
					top: true,
					closeable: false,
					timeout: 3000,
				});
				resetForm();
			})
			.catch((error: any) => {
				Emitter.emit("alert", {
					type: "error",
					message: "Failed to delete programme details. Please try again.",
					description: "",
					top: true,
					closeable: false,
					timeout: 3000,
				});
				resetForm(currentWellProgrammeData);
			})
			.finally(() => {
				Emitter.emit("loading", false);
				navigate("/administration", { replace: true });
			});
	};

	// Handle form Cancel
	const handleFormCancel = () => {
		context?.handleFormUpdating(false);
		resetForm(currentWellProgrammeData);
		// if (currentWellProject != "Add new project") {
		// 	const project = allWellProjects.find((project: any) => {
		// 		return project.operation_code == currentWellProject;
		// 	});
		//
		// 	resetForm(project);
		// } else {
		// 	resetForm();
		// }
	};

	const tabContent = () => {
		switch (tabKey) {
			case "well_programme_template":
				return (
					<>
						{
							<div
								style={{
									flex: "1",
									display: "flex",
									flexDirection: "row",
									overflow: "auto",
								}}
							>
								<div
									className="main-container"
									style={{
										width: "70%",
										display: "flex",
										flexDirection: "column",
										borderRadius: "3px",
									}}
								>
									<div className="generic-header" style={{ height: "50px" }}>
										Settings
									</div>
									<div className="generic-content" style={{ flex: "1" }}>
										<div style={{ flex: "1", position: "relative" }}>
											<CustomForm
												setFormReset={setFormReset}
												handleFormSave={handleFormSave}
												formRef={formRef}
												tabKey={tabKey}
												formOption={"general"}
												// dataOptions={context.allData}
												// activeRowKey={wellInfo._id}
												activeRecord={location.state}
											/>
										</div>
									</div>
									<div className="generic-footer" style={{ height: "50px" }}>
										{
											<Popconfirm
												overlayInnerStyle={{
													maxWidth: "300px",
													padding: "16px",
												}}
												placement="topLeft"
												okText="Delete"
												okType="danger"
												title=""
												icon={
													<ExclamationCircleOutlined
														style={{ color: "grey" }}
													/>
												}
												cancelText={"Cancel"}
												cancelButtonProps={{ ghost: true }}
												overlayClassName="popconfirm-danger"
												description="Permanently delete this well programme? This action cannot be undone."
												onOpenChange={(open: any) => {
													if (!open) {
														setEmptyModalOpen(false);
													}
												}}
												onConfirm={() => {
													setEmptyModalOpen(false);
													handleFormDelete(currentWellProgramme);
												}}
												onCancel={() => {
													setEmptyModalOpen(false);
												}}
											>
												<Button
													className="ant-btn-secondary"
													style={{ marginRight: "auto" }}
													danger
												>
													Delete
												</Button>
											</Popconfirm>
										}
										<a
											style={{
												display: "flex",
												padding: "4px 10px",
												gap: "8px",
												cursor: "pointer",
											}}
											onClick={() => {
												setAuditLogModalOpen(true);
											}}
										>
											{GetAntIcon("history")} Audit log
										</a>
										<FormButtonCancel
											form={formRef}
											handleFormCancel={handleFormCancel}
											formReset={formReset}
										></FormButtonCancel>
										<Popover
											open={popOverVisibility}
											onOpenChange={(boolean: any) => {
												// if (checkCode()) {
												// 	setPopOverVisibility(boolean);
												// } else {
												// 	setPopOverVisibility(false);
												// }
											}}
											overlayStyle={{
												maxWidth: "420px",
												background: "rgba(57,57,57,1)",
												borderRadius: "8px",
												// padding: "16px",
											}}
											style={{ background: "transparent" }}
											trigger={"click"}
											content={
												<div
													style={{
														display: "flex",
														flexDirection: "row",
														gap: "8px",
													}}
												>
													<Button
														className="ant-button-default"
														style={{
															marginRight: "auto",
															border: "1px solid rgba(255, 255, 255, 0.1)",
														}}
														ghost
														onClick={() => {
															setPopOverVisibility(false);
															handleFormSave(null, "New");
														}}
													>
														Create new
													</Button>
													<Button
														className="ant-button-default"
														style={{
															border: "1px solid rgba(255, 255, 255, 0.1)",
														}}
														ghost
														onClick={() => {
															setPopOverVisibility(false);
														}}
													>
														Cancel
													</Button>
													<Button
														className="ant-button-default"
														onClick={() => {
															setPopOverVisibility(false);
															handleFormSave(null, "code");
														}}
													>
														Proceed
													</Button>
												</div>
											}
											title={
												<Space>
													<div style={{ color: "grey" }}>
														{GetAntIcon("exclamation")}
													</div>
													<span style={{ fontWeight: "400" }}>
														Changing the business unit or client info affects
														the project code. Do you wish to proceed?
													</span>
												</Space>
											}
										>
											<div>
												<FormButtonSave
													form={formRef}
													handleFormSave={handleFormSave}
													formReset={formReset}
													setFormReset={setFormReset}
												></FormButtonSave>
											</div>
										</Popover>
									</div>
								</div>
							</div>
						}
					</>
				);

			case "well-programme":
				return (
					<CRUDTabTableModal
						items={[
							{
								label: "Phases and tasks",
								key: "phase",
								formOption: "template",
								directoryName: "Phases and tasks directory",
								configurationName: "Phase configuration",
								sortKey: "sequence_id",
								tableAuditLog: {
									identifierKeys: {},
									label: `Phases and tasks`,
									type: "phase_template",
									auditlogFilter: [
										{
											level: "phase_template",
											event_type: { $in: ["add", "delete", "reorder"] },
											"ref_tags.well_programme_template_id":
												currentWellProgrammeData?._id,
										},
										{
											level: "task_template",
											event_type: { $in: ["add", "delete"] },
											"ref_tags.well_programme_template_id":
												currentWellProgrammeData?._id,
										},
									],
								},
								showSideContainerAuditLog: true,
								// sideContainerAuditLog: {
								// 	identifierKeys: {},
								// 	label: `Phase`,
								// 	type: "phase_template",
								// 	auditlogFilter: [
								// 		{
								// 			level: "phase_template",
								// 			event_type: { $in: ["add", "update"] },
								// 			"ref_tags.well_programme_template_id":
								// 				currentWellProgrammeData?._id,
								// 		},
								// 		{
								// 			level: "task_template",
								// 			event_type: { $in: ["add", "delete"] },
								// 			"ref_tags.well_programme_template_id":
								// 				currentWellProgrammeData?._id,
								// 		},
								// 	],
								// },
							},
						]}
						tabKey={"phase"}
						formOption={"template"}
						otherData={currentWellProgrammeData}
						user={props.params?.user}
						reorderModal
					/>
				);
			default:
				return <></>;
		}
	};

	return (
		<>
			{!isLoading && (
				<div className="generic-container">
					<Space direction="vertical">
						<Select
							getPopupContainer={(trigger: any) => trigger.parentNode}
							className="title-selector"
							style={{
								fontSize: "20px",
								fontWeight: "600",
								color: "rgba(255, 255, 255, 0.85)",
								width: "auto",
							}}
							options={templateList}
							value={currentWellProgramme}
							open={dropdownOpen}
							onClick={() => {
								setDropdownOpen(!dropdownOpen);
							}}
							onChange={(key: any) => {
								handleTemplateChange(key, null, true);
								setDropdownOpen(false);
							}}
							onBlur={(event: any) => {
								event.preventDefault();
								setDropdownOpen(false);
							}}
							fieldNames={{
								label: "name",
								value: "_id",
							}}
							dropdownStyle={{ minWidth: "100%", width: "auto" }}
							dropdownRender={(menu) => {
								return <>{menu}</>;
							}}
						></Select>

						<Tabs
							activeKey={tabKey}
							onChange={handleTabChange}
							items={tabList}
						></Tabs>
					</Space>
					<div
						style={{
							flex: "1",
							display: "flex",
							flexDirection: "row",
							gap: "20px",
							maxHeight: "100%",
							overflow: "auto",
						}}
					>
						{tabContent()}
					</div>
					{/* <div className="side-profile-container" style={{ flex: "1" }}></div> */}
					<Modal
						className="empty-modal"
						open={emptyModalOpen}
						closable={false}
						maskClosable={true}
						onCancel={() => {
							setEmptyModalOpen(false);
						}}
						zIndex={1050}
						footer={<></>}
						style={{ background: "transparent" }}
					>
						<></>
					</Modal>
					{phaseTaskRecord?._id && (
						<TaskConfigurationModal
							open={taskModalOpen}
							setOpen={setTaskModalOpen}
							option={"template"}
							handleFormUpdate={handleTaskFormUpdate}
							handleModalClose={handleTaskModalClose}
							setDeliverableModalOpen={setDeliverableModalOpen}
							setDeliverableRecord={setDeliverableRecord}
							record={phaseTaskRecord}
							allCampaignTasks={allCampaignTasks}
							otherData={currentWellProgrammeData}
							phaseID={phaseTaskRecord?._id}
							taskRecord={phaseTaskRecord?.task}
							activeRowKey={phaseTaskRecord?.task?._id}
							extraData={[]}
							user={props.params?.user}
							userRole={props.params?.userRole}
						/>
					)}
					<DeliverableConfigurationModal
						open={deliverableModalOpen}
						setOpen={setDeliverableModalOpen}
						option={"template"}
						handleFormUpdate={handleDeliverableFormUpdate}
						handleModalClose={handleDeliverableModalClose}
						otherData={currentWellProgrammeData}
						phaseID={phaseTaskRecord?._id}
						taskID={phaseTaskRecord?.task?._id}
						deliverableRecord={deliverableRecord}
						taskRecord={phaseTaskRecord?.task}
						activeRowKey={deliverableRecord?._id}
						extraData={[]}
						user={props.params?.user}
						userRole={props.params?.userRole}
					/>
					{auditLogModalOpen && (
						<AuditLogModal
							{...auditLogProps}
							tenantKey={context?.currentTenantKey}
							open={auditLogModalOpen}
							handleClose={() => setAuditLogModalOpen(false)}
						/>
					)}
				</div>
			)}
		</>
	);
}

WellProgrammeTemplate.propTypes = {
	params: PropTypes.any,
};

export default WellProgrammeTemplate;
