import React, { useState, useImperativeHandle, forwardRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 

interface TextEditorProps {
    onChange: (html: string) => void; // Prop type definition
    value: string; // Add value prop for initial content
    onClick?: () => void;
    onBlur?: () => void;
}

interface TextEditorHandle {
    clearEditor: () => void; // Method to clear the editor
    getEditor: () => { // New method to get the editor's content
        root: {
            innerHTML: string;
        };
    };
}

const TextEditor = forwardRef<TextEditorHandle, TextEditorProps>(({ onChange, onClick, onBlur, value }, ref) => {
    const [editorHtml, setEditorHtml] = useState<string>(value);

    const handleChange = (html: string) => {
        setEditorHtml(html);
        onChange(html); // Call the prop function to notify the parent
    };

    const clearEditor = () => {
        setEditorHtml(''); // Reset the editor content
    };

    // Method to get the editor content
    const getEditor = () => {
        return {
            root: {
                innerHTML: editorHtml, // Get the current HTML content
            },
        };
    };

    useImperativeHandle(ref, () => ({
        clearEditor,
        getEditor, // Expose getEditor method
    }));

    const toolbarOptions = [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline'],
        [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
        ['clean'],
    ];

    return (
        <div className="editor-container" onClick={onClick} onBlur={onBlur}>
            <ReactQuill 
                value={editorHtml}
                onChange={handleChange}
                modules={{ toolbar: toolbarOptions }}
                formats={['header', 'bold', 'italic', 'underline', 'list', 'indent']}
                placeholder="Enter a comment"
            />
        </div>
    );
});

export default TextEditor;
