/**
 * 	Terra's main page, contains the Layout, Header, Footer, and Side Menu.
 *  Any other content will be available in the <Outlet/> element, which renders
 * 	components based on the current endpoint in the URL
 */

import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { MainContext } from "../contexts/context";
import { Dropdown, Image, Input, Layout, Popconfirm, Space, Spin } from "antd";
import Icon, { ExclamationCircleOutlined } from "@ant-design/icons";
import { Outlet, useNavigate } from "react-router";
// import elementalLogo from "../assets/image/elementalLogo.png";
import terraLogoFooterLight from "../assets/image/TerraLogo_Footer_Lightmode.svg";
import terraLogoFooterDark from "../assets/image/TerraLogo_Footer_Darkmode.svg";
import elementalLoadingLogo from "../assets/image/elemental 'e'.svg";
import elementalLoadingOutline from "../assets/image/outline 'e'.svg";
import MainMenu from "../services/MainMenu";
import { GetAntIcon } from "../utils/ant_icons";
import Emitter from "../utils/emitter";
import file from "../../package.json";
import LeaveWithChangesModal from "../components/LeaveWithChangesModal";
import { getInitials } from "../utils/utils";
import { getBusinessUnitList } from "../services/api-server/business_unit";
import { getProjectTagList } from "../services/api-server/project_tags";
import { getOperatingOfficeList } from "../services/api-server/operating_office";
import { getProjects } from "../services/api-server/projects";
import { getRegionList } from "../services/api-server/region";
import { getCompanyList } from "../services/api-server/company";
import { getRolePermissionList } from "../services/api-server/roles_permissions";
import { getTenantSpecificUsers } from "../services/api-server/graphql";
import { ErrorBoundary } from "react-error-boundary";
import NoAccess from "./NoAccess";
import { sendErrorNotification } from "../services/api-server/base";
import { getWellPurposeList } from "../services/api-server/purposes";
import { getWellPurposeTypeList } from "../services/api-server/purpose_types";
import { getWellStatusList } from "../services/api-server/status";
import { getWellStatusTypeList } from "../services/api-server/status_types";
import { getOperationTypeList } from "../services/api-server/operation_type";
import BreadcrumbComponent from "../components/BreadcrumbComponent";
import { getWellProgrammeTemplateList } from "../services/api-server/well_programme_template";
import ContextMenu from "../components/ContextMenu";
import ePng from "../assets/image/disable 'e'.png";

const { Header, Content, Footer } = Layout;

export const HomeContext = React.createContext({});

const Home = (props: any) => {
	const formUpdatingRef = useRef<any>([]);
	const [menuProps, setMenuProps] = useState<any>([]);
	const [allData, setAllData] = useState<any>(null);
	const [isLoading, setLoading] = useState<any>(false);
	const [selectedKey, setSelectedKey] = useState<any>([]);
	const [isCollapsed, setCollapsed] = useState<boolean>(true);
	const [currentTenantData, setCurrentTenantData] = useState<any>(null);
	const [currentTenantKey, setCurrentTenantKey] = useState<any>(null);
	const [currentProject, setCurrentProject] = useState<any>({});
	const [currentWell, setCurrentWell] = useState<any>({});
	const [currentOperation, setCurrentOperation] = useState<any>({});
	const [currentPhase, setCurrentPhase] = useState<any>({});
	const [currentTask, setCurrentTask] = useState<any>({});

	const tenantData = props?.tenants;
	const [pageChange, setPageChange] = useState<boolean>(false);
	const [popconfirmDialogOpen, setPopconfirmDialogOpen] =
		useState<boolean>(false);
	const [tenantDropdownOpen, setTenantDropdownOpen] = useState<boolean>(false);

	const [errorBoundaryKey, incrementErrorBoundaryKey] = useState(0);

	const [contextMenuPosition, setContextMenuPosition] = useState<{
		mouseX: number;
		mouseY: number;
	} | null>(null);
	const [contextMenuInfo, setContextMenuInfo] = useState<any>(null);

	useEffect(() => {
		if (currentTenantKey && currentTenantData) {
			Emitter.emit("loading", true);
			let allData: any = {};
			Promise.all([
				getBusinessUnitList(currentTenantKey).then((_data: any) => {
					if (_data) {
						allData.strategic_business_unit = _data;
					}
				}),

				getProjectTagList(currentTenantKey).then((_data: any) => {
					if (_data) {
						allData.project_tags = _data;
					}
				}),

				getOperatingOfficeList(currentTenantKey).then((_data: any) => {
					if (_data) {
						allData.operating_office = _data;
					}
				}),

				getProjects(currentTenantKey, currentTenantData?.company_list).then(
					(_data: any) => {
						if (_data) {
							allData.projects = _data;
						}
					}
				),

				getRegionList(currentTenantKey).then((_data: any) => {
					if (_data) {
						allData.region = _data;
					}
				}),

				// getCompanyList(currentTenantData?.company_list).then((_data: any) => {
				// 	if (_data) {
				// 		allData.companies = _data;
				// 	}
				// }),

				getCompanyList(currentTenantKey, [], true).then((_data: any) => {
					if (_data) {
						allData.company = _data;
					}
				}),

				getRolePermissionList(currentTenantKey).then((_data: any) => {
					if (_data) {
						allData.roles = _data;
					}
				}),

				getTenantSpecificUsers(currentTenantData?.uuid).then(
					(tenantUsers: any) => {
						if (tenantUsers) {
							allData.users = tenantUsers;
						}
						if (currentTenantData?.project_managers) {
							let projectManagerList = currentTenantData?.project_managers;
							allData.project_managers = projectManagerList;
						}
					}
				),
				getWellPurposeList().then((_data: any) => {
					if (_data) {
						allData.well_purpose = _data;
					}
				}),
				getWellPurposeTypeList().then((_data: any) => {
					if (_data) {
						allData.well_purpose_type = _data;
					}
				}),
				getWellStatusList().then((_data: any) => {
					if (_data) {
						allData.well_status = _data;
					}
				}),
				getWellStatusTypeList().then((_data: any) => {
					if (_data) {
						allData.well_status_type = _data;
					}
				}),
				getOperationTypeList().then((_data: any) => {
					if (_data) {
						allData.operation_type = _data;
					}
				}),
				getWellProgrammeTemplateList(currentTenantKey).then((_data: any) => {
					if (_data) {
						allData.well_programme_template = _data;
					}
				}),
			])
				.then((data: any) => {
					allData.userInfo = props?.userInfo;
					setAllData(allData);
				})
				.finally(() => {
					Emitter.emit("loading", false);
				});
		}
	}, [currentTenantData, currentTenantKey]);

	const handleErrorFallback = (props: any) => {
		return (
			<NoAccess
				text={"An Error has occured, please try again later."}
				setErrorBoundaryKey={() => {
					incrementErrorBoundaryKey(errorBoundaryKey + 1);
				}}
			/>
		);
	};

	const logError = (error: any, info: any) => {
		if (process.env.NODE_ENV === "production") {
			error.location = window.location.pathname;
			error.level = "component";
			let JSONerror = JSON.stringify(error, Object.getOwnPropertyNames(error));
			sendErrorNotification(JSONerror);
		}
	};

	useEffect(() => {
		Emitter.on("menuChange", (menuProps: any) => {
			if (
				localStorage.getItem("selectedFilters") &&
				menuProps.container !== "projectSettings"
			) {
				localStorage.removeItem("selectedFilters");
			}
			setMenuProps(menuProps);
		});

		Emitter.on("loading", (payload: any) => {
			setLoading(payload);
		});
	}, []);

	useEffect(() => {
		const tenant = localStorage.getItem("currentTenantKey");
		if (tenantData) {
			if (tenant) {
				let foundTenant = tenantData?.find(
					(element: any) => element?.value === tenant
				);
				if (foundTenant) {
					setCurrentTenantKey(tenant);
					setCurrentTenantData({ ...foundTenant });
					localStorage.setItem("currentTenantKey", tenant);
				} else {
					if (tenantData.length > 0) {
						setCurrentTenantData({ ...props.tenants[0] });
						setCurrentTenantKey(props.tenants[0]?.value);
						localStorage.setItem("currentTenantKey", props.tenants[0]?.value);
					}
				}
			} else {
				setCurrentTenantData({ ...props.tenants[0] });
				setCurrentTenantKey(props.tenants[0]?.value);
				localStorage.setItem("currentTenantKey", props.tenants[0]?.value);
			}
		}
	}, [props.tenants]);

	useEffect(() => {
		let menu = props.menu;
		let currentlocation = "";
		currentlocation = props.currentLocation.split("/");

		let menuItem = menu.find((element: any) => {
			let route: any = element?.item?.to.split("/").pop();
			return currentlocation?.includes(route);
		});
		if (menuItem) {
			incrementErrorBoundaryKey(errorBoundaryKey + 1);
			if (menuItem?.item?.requires_admin == true) {
				setMenuProps([{ title: "Administration" }, menuItem?.item]);
			} else {
				setMenuProps([menuItem?.item]);
			}

			setSelectedKey(menuItem.item.key);
		}
		handleFormUpdating(false);
	}, [props.currentLocation]);

	const onCollapse = (collapseState: any) => {
		setCollapsed(collapseState);
	};

	const handleFormUpdating = (bool: any, key: any = null) => {
		if (key === null) {
			key = "new";
		}
		setPageChange(false);
		if (bool == true && key) {
			formUpdatingRef.current[key] = true;
		} else if (bool == false && key) {
			delete formUpdatingRef.current[key];
		}
	};

	const handlePageChange = () => {
		return new Promise((resolve, reject) => {
			setPageChange(true);
			let length = Object.keys(formUpdatingRef.current).length;
			if (length > 0) {
				Emitter.on("leave", () => {
					resolve(true);
					formUpdatingRef.current = {};
					setPageChange(false);
					Emitter.remove("leave");
					Emitter.remove("stay");
					sessionStorage.removeItem("tabKey");
				});
				Emitter.on("stay", () => {
					reject(false);
					setPageChange(false);
					Emitter.remove("leave");
					Emitter.remove("stay");
				});
			} else {
				sessionStorage.removeItem("tabKey");
				resolve(true);
				formUpdatingRef.current = {};
				setPageChange(false);
			}
		});
	};

	const handleTenantSwitch = (data: any) => {
		localStorage.setItem("currentTenantKey", data.value);
		window.location.href = "/dashboard";
	};

	const displayLogo = (data: any) => {
		return data?.logo ? (
			<Image
				style={{ borderRadius: "180px", minWidth: "32px", maxWidth: "32px" }}
				width={32}
				height={32}
				preview={false}
				src={data?.logo}
			></Image>
		) : (
			<div
				className="profile-picture-initials"
				style={{
					width: "32px",
					minWidth: "32px",
					maxWidth: "32px",
					height: "32px",
				}}
			>
				<span className="profile-initials" style={{ fontSize: "12px" }}>
					{getInitials(data?.name || data?.displayName, 0)}
				</span>
			</div>
		);
	};

	const renderTenantDropdown = () => {
		const renderTenantButtons = (data: any) => {
			return (
				<Popconfirm
					disabled={data?.value === currentTenantKey}
					placement="right"
					overlayInnerStyle={{
						maxWidth: "300px",
						padding: "16px",
					}}
					title=""
					description={`Switch to ${
						data.name || data.displayName
					}? This will log you out of the current session `}
					onOpenChange={(boolean: any) => {
						setPopconfirmDialogOpen(boolean);
					}}
					onCancel={() => {
						handleTenantSwitch(data);
					}}
					overlayClassName="popconfirm-danger popconfirm-split-buttons"
					okText="Stay on current tenant"
					okType="default"
					icon={<ExclamationCircleOutlined style={{ color: "grey" }} />}
					cancelText={<span>Switch</span>}
					cancelButtonProps={{ ghost: true }}
				>
					<div
						className={
							data?.value === currentTenantKey
								? "tenant-button-active"
								: "tenant-button"
						}
					>
						{displayLogo(data)}
						<span>{data.name || data.displayName}</span>
					</div>
				</Popconfirm>
			);
		};
		return (
			<div
				style={{
					minWidth: "300px",
					padding: "14px 10px",
					display: "flex",
					flexDirection: "column",
					gap: "10px",
					alignItems: "flex-start",
				}}
			>
				{tenantData
					.sort((a: any, b: any) => {
						if (a?.value === currentTenantKey) {
							return -1;
						} else if (b?.value === currentTenantKey) {
							return 1;
						} else {
							return 0;
						}
					})
					.map((data: any) => renderTenantButtons(data))}
			</div>
		);
	};

	return (
		<MainContext.Consumer>
			{({ menuOption }) => {
				return (
					<HomeContext.Provider
						value={{
							isLoading: isLoading,
							allData: allData,
							currentTenantData: currentTenantData,
							currentTenantKey: currentTenantKey,
							currentProject: currentProject,
							currentWell: currentWell,
							currentOperation: currentOperation,
							currentPhase: currentPhase,
							currentTask: currentTask,
							setCurrentTenantData: setCurrentTenantData,
							setCurrentProject: setCurrentProject,
							setCurrentWell: setCurrentWell,
							setCurrentOperation: setCurrentOperation,
							setCurrentPhase: setCurrentPhase,
							setCurrentTask: setCurrentTask,
							handleFormUpdating: handleFormUpdating,
							handlePageChange: handlePageChange,
							setContextMenuPosition: setContextMenuPosition,
							setContextMenuInfo: setContextMenuInfo,
						}}
					>
						<Layout className="site-whole">
							<MainMenu
								collapsed={isCollapsed}
								onCollapse={(collapseState: boolean) => {
									onCollapse(collapseState);
								}}
								selectedKey={selectedKey}
								option={menuOption}
								roles={props.userInfo.userRole}
							></MainMenu>
							<Layout className="site-layout">
								<Header className="site-header">
									{allData ? <BreadcrumbComponent /> : <div>{""}</div>}

									{props?.tenants?.length > 0 && (
										<Dropdown
											open={popconfirmDialogOpen || tenantDropdownOpen}
											dropdownRender={() => (
												<div style={{ width: "100%" }}>
													{renderTenantDropdown()}
												</div>
											)}
											placement="bottomRight"
											trigger={["hover"]}
											onOpenChange={(boolean: any) => {
												setTenantDropdownOpen(boolean);
											}}
										>
											{displayLogo(currentTenantData)}
										</Dropdown>
									)}
									{/* <Input
										placeholder="Search"
										style={{ maxWidth: "265px" }}
										prefix={GetAntIcon("search")}
									/> */}
								</Header>
								<Content className="site-content">
									<Spin
										//Maximum ZIndex
										style={{ zIndex: 99999 }}
										size="large"
										tip={
											<div
												style={{
													display: "flex",
													flexDirection: "column",
													justifyContent: "center",
													alignItems: "center",
												}}
											>
												{/* <span style={{ fontSize: "30px" }}>{ModelLoadDesc}</span> <Progress style={{ width: "50%" }} percent={ModelLoadPercent} /> */}
											</div>
										}
										indicator={
											<div
												style={{
													display: "flex",
													alignItems: "center",
													justifyContent: "center",
													top: 0,
													insetInlineStart: 0,
													height: "100%",
													width: "100%",
													margin: 0,
												}}
											>
												<img
													style={{ position: "absolute", width: "10%" }}
													src={elementalLoadingLogo}
												/>
												<img
													className="spin"
													style={{ position: "absolute", width: "15%" }}
													src={elementalLoadingOutline}
												/>
											</div>
										}
										spinning={isLoading}
									>
										<ErrorBoundary
											resetKeys={[errorBoundaryKey]}
											fallbackRender={handleErrorFallback}
											onError={logError}
										>
											<Outlet />
										</ErrorBoundary>
									</Spin>
								</Content>
								<Footer className="site-footer">
									<span>Version {file.version}</span>
									<Space size={15}>
										<span>&copy; 2024 Elemental Energies</span>
										<Image
											preview={false}
											width="50px"
											src={
												props.userInfo.user?.theme === "light"
													? terraLogoFooterLight
													: terraLogoFooterDark
											}
										/>
									</Space>
								</Footer>

								<LeaveWithChangesModal
									isFormUpdating={formUpdatingRef.current}
									setFormUpdating={handleFormUpdating}
									pageChange={pageChange}
								/>

								<ContextMenu
									position={contextMenuPosition}
									info={contextMenuInfo}
								/>
							</Layout>
						</Layout>
					</HomeContext.Provider>
				);
			}}
		</MainContext.Consumer>
	);
};

Home.propTypes = {
	userInfo: PropTypes.any,
	currentLocation: PropTypes.any,
	menu: PropTypes.any,
	tenants: PropTypes.any,
};

export default Home;
