//Login Logos

//Home Logos
import { ReactComponent as LogoElemental } from "../assets/image/secondary_logo.svg";
import { Tenant } from "../App";
import { Image } from "antd";
import Icon from "@ant-design/icons";

export const setLoginLogo = () => {
	switch (Tenant) {
		case "elemental":
			return (
				<img
					className="company-logo"
					style={{ width: "200px", position: "relative", left: "-10px" }}
					src={undefined}
					alt="Logo"
				/>
			);
		default:
			return null;
	}
};

export const setHomeLogo = () => {
	switch (Tenant) {
		case "elemental":
			return <LogoElemental width={"35px"} />;
		default:
			return <></>;
	}
};

export const setDrawerlogo = (logo_link: any) => {
	if (logo_link) {
		return (
			<img
				className="drawer-logo"
				style={{ width: "90px", marginTop: "5px" }}
				src={logo_link}
				alt="Drawer_logo"
			/>
		);
	} else {
		return <></>;
	}
};
