import React, {
	Dispatch,
	SetStateAction,
	useState,
	DragEvent,
	FormEvent,
	useEffect,
} from "react";
import { motion } from "framer-motion";
import { Collapse, Divider, Progress, Space, Tooltip, Typography } from "antd";
import { useNavigate } from "react-router";
import { CaretDownOutlined, CaretRightOutlined } from "@ant-design/icons";
import { excludedMark, statusIcon } from "../utils/statusIcon";
import { updateTaskItem } from "../services/api-server/tasks";
import useHandleContextMenu from "../hooks/useHandleContextMenu";
import { GetAntIcon } from "../utils/ant_icons";

export const KanbanBoardDnD = (props: any) => {
	const [cards, setCards] = useState<any>([]);
	const [filteredCards, setFilteredCards] = useState<any>(null);
	const [openModal, setOpenModal] = useState<boolean>(false);

	useEffect(() => {
		setCards(props.cardData);
	}, []);

	useEffect(() => {
		setCards(props.cardData);
	}, [props.cardData]);

	useEffect(() => {
		let filteredData: any = [];
		const { searchValue, selectedFilters } = props;
		// I can make this a function that passed as props from the parent component

		const check = Object.keys(selectedFilters).some(
			(filterKey: any) => selectedFilters[filterKey].length > 0
		);

		if (check || searchValue != "") {
			if (searchValue != "") {
				filteredData = cards?.filter(
					(task: any) =>
						task?.task_name
							?.toLowerCase()
							.includes(searchValue.toLowerCase()) ||
						task.task_number === searchValue
				);
			} else {
				filteredData = cards;
			}

			if (check) {
				filteredData = filteredData?.filter((task: any) =>
					Object.keys(selectedFilters).every((filterKey: any) => {
						if (selectedFilters[filterKey].length == 0) {
							return true;
						}
						if (typeof task[filterKey] == "object") {
							return task[filterKey].some((key: any) =>
								selectedFilters[filterKey].includes(key)
							);
						} else {
							return selectedFilters[filterKey].includes(task[filterKey]);
						}
					})
				);
			}
		} else {
			filteredData = null;
		}

		setFilteredCards(filteredData);
	}, [props.searchValue, props.selectedFilters, cards]);

	return (
		<div
			className="kanban-columns-container"
			style={{
				display: "flex",
				flex: 1,
				alignItems: "flex-start",
				padding: "30px",
				gap: "25px",
				alignSelf: "stretch",
				overflow: "auto",
			}}
		>
			{props.columnList.map((column: any) => {
				return (
					<Column
						column={column}
						cards={filteredCards != null ? filteredCards : cards}
						headerList={props.cardHeader}
						// progressCount={props.progressCount}
						setCards={setCards}
						allExpandList={props.allExpandList}
						updateCardStatus={props.updateCardStatus}
						setBlockedModalOpen={props.setBlockedModalOpen}
						setActiveKey={props.setActiveKey}
						isSaved={props.isSaved}
						isCanceled={props.isCanceled}
					/>
				);
			})}
		</div>
	);
};

const Column = ({
	column,
	cards,
	headerList,
	// progressCount,
	setCards,
	allExpandList,
	updateCardStatus,
	setBlockedModalOpen,
	setActiveKey,
	isSaved,
	isCanceled,
}: any) => {
	const [active, setActive] = useState(false);
	const [textHighlight, setTextHighlight] = useState(false);

	useEffect(() => {
		const checkTextHighlight = () => {
			const selection = window.getSelection();
			if (selection?.toString() !== "") {
				setTextHighlight(true);
			} else {
				setTextHighlight(false);
			}
		};
		document.addEventListener("mouseup", checkTextHighlight);
		document.addEventListener("mousedown", checkTextHighlight);
		return () => {
			document.removeEventListener("mouseup", checkTextHighlight);
			document.removeEventListener("mousedown", checkTextHighlight);
		};
	}, []);

	const filteredCards = cards?.filter((card: any) => {
		if (column.columnName === "Closed") {
			return card.closed === true;
		} else {
			if (card.closed !== true) return card.status === column.status;
		}
	});

	// Dragging events
	const handleDragStart = (e: DragEvent, card: any) => {
		e.dataTransfer.setData("cardId", card._id);
	};

	const handleDrop = (e: DragEvent) => {
		const cardId = e.dataTransfer.getData("cardId");

		// remove the card from the previous column and add it to this column
		let copy = [...cards];
		let card = copy.find((card: any) => card._id === cardId);
		if (!card || textHighlight) {
			setActive(false);
			return;
		}
		if (column.status === "Late" && card.status !== "Late") {
			setBlockedModalOpen(true);
			setActive(false);
			setActiveKey(cardId);
			return;
		}
		if (column.status === card.status) {
			setActive(false);
			return;
		}
		let newCard = { ...card, status: column.status };
		setActive(false);
		if (newCard.status !== "Late") {
			newCard.blocker = null;
			newCard.plan = null;
		}
		// update the card status in frontend
		setCards(
			copy
				.filter((c: any) => c._id !== cardId)
				.concat(newCard)
				.sort((a: any, b: any) => {
					if ("sequence_id" in a && "sequence_id" in b) {
						if (a.sequence_id && b.sequence_id) {
							return b.sequence_id - a.sequence_id;
						}
						return 0;
					}
					return 0;
				})
		);
		updateCardStatus(cardId, newCard, card); // update the card status in the backend
	};

	const handleDragOver = (e: DragEvent) => {
		e.preventDefault();
		setActive(true);
	};

	const handleDragLeave = (e: DragEvent) => {
		e.preventDefault();
		setActive(false);
	};

	const filterExpandList = (list: any, cardId: string) => {
		return list?.filter((item: any) => item.task_id === cardId) || [];
	};

	return (
		<div className="project-status-container">
			<div className="project-status-container-header">
				{GetAntIcon("square-check")}
				{column.columnName}
			</div>
			<div
				onDrop={
					textHighlight || column.droppable === false ? undefined : handleDrop
				}
				onDragOver={
					textHighlight || column.droppable === false
						? undefined
						: handleDragOver
				}
				onDragLeave={handleDragLeave}
				className="project-status-container-content"
				style={active ? { backgroundColor: "rgba(3, 40, 43, 0.4)" } : {}}
			>
				{filteredCards
					.sort((a: any, b: any) => {
						if ("sequence_id" in a && "sequence_id" in b) {
							// if (a.sequence_id && b.sequence_id) {
							return a.sequence_id - b.sequence_id;
							// }
							// return 0;
						}
						return 0;
					})
					.map((card: any) => {
						return (
							<Card
								key={card._id}
								card={card}
								column={column}
								headerList={headerList}
								// progressCount={progressCount}
								handleDragStart={handleDragStart}
								expandList={filterExpandList(allExpandList, card._id)}
							/>
						);
					})}
			</div>
		</div>
	);
};

const Card = ({
	card,
	column,
	headerList,
	// progressCount,
	handleDragStart,
	expandList,
}: any) => {
	const [percent, setPercent] = useState(card.progress);
	// const [fraction, setFraction] = useState("0/0");
	const navigate = useNavigate();

	const { handleContextMenu } = useHandleContextMenu();

	// useEffect(() => {
	// 	progressCount(card._id)
	// 		.then((res: any) => {
	// 			// setPercent(res.percent);
	// 			setPercent(res.progress);
	// 			let _fraction = `${res.completed}/${res.total}`;
	// 			setFraction(_fraction);
	// 		})
	// 		.catch((error: any) => {
	//
	// 		});
	// }, []);

	// Get the card name which is the combination of the header list
	const cardName = () => {
		let name: string[] = [];
		headerList.forEach((head: any) => {
			name.push(card[head]);
		});
		return name.join(" ");
	};

	const displayExpandList = (list: any) => {
		return list.map((item: any) => {
			let tooltipMessage: any = "";
			if (item.disabled && item.disabled_reason) {
				tooltipMessage = (
					<span>
						This deliverable is excluded from the well programme. You may still
						click to view the details. <br />
						<br /> <b>Comment:</b>
						<br />
						{item?.disabled_reason}
					</span>
				);
			}

			return (
				<Tooltip title={tooltipMessage}>
					<div
						className={
							item.disabled
								? "deliverable-item-disabled"
								: "project-title-clickable"
						}
						onClick={() => {
							navigate(`${card._id}`, {
								replace: false,
								state: { task_id: card._id, deliverable_id: item._id },
							});
						}}
						// onContextMenu={(e: any) => {
						// 	const location = `${window.location.href}/${card._id}`;
						// 	handleContextMenu(e, { link: location });
						// }}
						style={{
							display: "flex",
							gap: "10px",
							margin: "5px 0px",
							cursor: "pointer",
						}}
						// className="project-title-clickable"
					>
						{item.disabled && excludedMark()} {statusIcon(item.status)}{" "}
						{item.deliverable_name}
					</div>
				</Tooltip>
			);
		});
	};

	return (
		<>
			<motion.div
				layout
				layoutId={card._id}
				draggable={card?.disabled || column.droppable === false ? false : true}
				className={
					!card.disabled ? "project-card" : "project-card task-card-disabled"
				}
				onDragStart={(e) => handleDragStart(e, card)}
				style={{ cursor: "default" }} // temporary update, remove when drag and drop is ready
			>
				<Space
					key={card._id}
					className="project-card-container"
					styles={{ item: { width: "100%" } }}
				>
					<a
						onClick={() => {
							navigate(`${card._id}`, {
								replace: false,
								state: { task_id: card._id },
							});
						}}
						onContextMenu={(e: any) => {
							const location = `${window.location.href}/${card._id}`;
							handleContextMenu(e, { link: location });
						}}
					>
						<Typography.Text
							title=""
							className={"project-title-clickable"}
							style={{
								whiteSpace: "nowrap",
								overflow: "hidden",
								textOverflow: "ellipsis",
							}}
							ellipsis={{
								tooltip: <span>{cardName()}</span>,
							}}
						>
							<span style={{ display: "flex", gap: "5px" }}>
								{card.disabled && excludedMark()}
								{cardName()}
							</span>
						</Typography.Text>
					</a>

					{expandList.length !== 0 && (
						<>
							<Progress
								style={{ width: "100%" }}
								percent={Math.round(percent)}
								format={() => {
									// count the number of deliverables that is completed
									const totalCompleted = expandList.filter(
										(item: any) => item.status === "Resolved"
									).length;
									return `${totalCompleted}/${expandList.length}`;
								}}
							/>
							<Collapse
								ghost
								expandIcon={({ isActive }) => (
									<div
										style={{
											display: "flex",
											gap: "5px",
											flex: "auto",
											width: "100%",
										}}
									>
										<CaretRightOutlined rotate={isActive ? 90 : 0} />
										<Divider />
									</div>
								)}
								expandIconPosition="end"
								items={[
									{
										key: "1",
										label: "Deliverables",
										children: <div>{displayExpandList(expandList)}</div>,
									},
								]}
							/>
						</>
					)}
				</Space>
			</motion.div>
		</>
	);
};
