import React from "react";
import newSvg from "../assets/image/new.svg";
import inProgressSvg from "../assets/image/InProgress.svg";
import inReviewSvg from "../assets/image/InReview.svg";
import completedSvg from "../assets/image/completed.svg";
import blockedSvg from "../assets/image/blocked.svg";
import { GetAntIcon } from "./ant_icons";

type Status = "New" | "In progress" | "In review" | "Resolved" | "Late";

export const statusIcon = (status: Status): JSX.Element => {
	switch (status) {
		case "New":
			return <img src={newSvg} />;
		case "In progress":
			return <img src={inProgressSvg} />;
		case "In review":
			return <img src={inReviewSvg} />;
		case "Resolved":
			return <img src={completedSvg} />;
		case "Late":
			return <img src={blockedSvg} />;
		default:
			// Handle unexpected status
			return <img src={newSvg} />;
	}
};

// Mark for excluded/disabled phase/task/deliverable
export const excludedMark = () => {
	return (
		<div style={{ display: "flex", alignItems: "center", opacity: "0.6" }}>
			(Excl.)
		</div>
	);
};
